const BettisHydra = () => {
  return (
    <div>
      <p>The Bettis PressureGuard Hydraulic Emergency Shutdown System is a reliable and robust solution for ensuring quick and effective shutdowns in critical situations. With its advanced hydraulic actuation technology, it provides precise control and protection for a wide range of industrial applications.</p>
      <h1>Features:</h1>
      <ul className="ul1">
      <li>Closed loop system</li>
      <li>Closes on loss of power to solenoid and or high / low pressure changes</li>
      <li>Advanced hydraulic actuation technology</li>
      <li>High reliability for critical shutdowns  </li>
      <li>Precise control and protection</li>
      <li>Compatibility with a wide range of industrial applications</li>
      <li>Fully integrated assemblies: actuator, valve, bracket, ISO test valves, and pressure pilots</li>
      <li>Robust construction for durability in harsh environments</li>
 	<li className="li1">Torque Ranges:
<ul className="ul1">
 	<li className="li1">1,530lbs - 28,566lbs</li>
</ul>
</li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
 	<li className="li1">Wellhead ESDV Quarter Turn Ball Valves</li>
 	<li className="li1">Pipeline Operations</li>
 	<li className="li1">Tank Farm Operations</li>
 	<li className="li1">Terminal Operations</li>
 	<li className="li1">Pumping Stations</li>
 	<li className="li1">Compressor Stations</li>
</ul>
      <h1>Product Link:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/actuators/hydraulic-actuators/bettis-pressureguard-hydraulic-emergency-shutdown-system/">Bettis Pressure Guard Hydraulic Actuator Product Page </a></li>
      </ul>
    </div>
  )
}

export default BettisHydra