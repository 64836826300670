const SpectraCamera = () => {
  return (
    <div>
      <p>Spectra Enhanced offers exceptional image quality, precise control, and advanced features for reliable and effective outdoor surveillance.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">High-Performance PTZ Camera System</li>
 	<li className="li1">Exceptional Image Quality</li>
 	<li className="li1">Precise Controls</li>
 	<li className="li1">Wide Area Coverage - 360° views</li>
 	<li className="li1">Customizable</li>
 	<li className="li1">Pelco Advanced Analytics: Built in analytics to detect critical events before they happen</li>
 	<li className="li1">Strong Low-Light Performance: High-definition imaging in various lighting</li>
 	<li className="li1">Direct Drive Closed-Loop Control: blazing pan and tilt speeds to position the camera on target</li>
 	<li className="li1">Pelco Smart Compression Technology: Reduce bandwidth and storage needs without compromising image quality</li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
 	<li className="li1">Security and surveillance</li>
 	<li className="li1">Available in standard or pressurized 30x optical zoom module for tough outdoor environments</li>
 	<li className="li1">Critical infrastructure protection</li>
 	<li className="li1">Border and perimeter security</li>
</ul>
      <h1>Product Link:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.pelco.com/products/cameras/ptz-cameras/spectra-enhanced/">Pelco Spectra Enhanced PTZ Dome Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default SpectraCamera
