const BettisQuarterTurnFailSafe = () => {
  return (
    <div>
      <p>Bettis RTS FQ Fail-Safe Quarter-Turn Electric Actuator is a precise process control electric actuators that help companies achieve reduced emissions at the wellpad, ensuring unparalleled safety, efficiency, and reliability while minimizing environmental impact. Our actuators provide superior control and accuracy, enabling operators to achieve maximum efficiency with minimal waste and reduce energy consumption and carbon footprint.</p>
      <h1>Features:</h1>
      <ul className="ul1">
    <li className="li1">Advanced safety &amp; reliability</li>
    <li className="li1">Fail-safe design for safety in case of power failure or emergency</li>
    <li className="li1">True mechanical fail safe (compressed spring)</li>
    <li className="li1">No separate UPS, battery backup or super capacitors required</li>
    <li className="li1">Advanced diagnostic capabilities for real-time feedback on valve performance</li>
    <li className="li1">Precise control over fluid and gas flow, reducing emissions and energy consumption</li>
    <li className="li1">Easy installation and maintenance, reducing downtime and costs</li>
    <li className="li1">Robust construction for reliable operation in harsh industrial environments</li>
    <li className="li1">Low power draw, .8mA idle / max 4.6 amp at full speed and torque/thrust</li>
    <li className="li1">Advanced optimization &amp; control</li>
    <li className="li1">Precise process control - .1%</li>
    <li className="li1">Advanced diagnostics and protocols - PID control / HART / Modbus / Profibus</li>
</ul>
      <h1>Applications:</h1>
      <ul>
    <li>Wellhead ESD choke valves - angle body and in-line</li>
    <li>Quarter turn butterfly and plug valves</li>
    <li>ESDV - quarter turn ball valves</li>
</ul>

      <h1>Product Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-fq-fail-safe-quarter-turn-electric-actuator/" target="_blank" rel="noopener noreferrer">Bettis RTS FQ Actuator Product Page</a></li>
        <li><a className="text-blue-500" href="https://www.emerson.com/en-us/catalog/emerson-dcmlink-software" target="_blank" rel="noopener noreferrer">Asset monitoring - DCM Link Software</a></li>
      </ul>
    </div>
  )
}
  
export default BettisQuarterTurnFailSafe
