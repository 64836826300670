const Enardo2000Smart = () => {
  return (
    <div>
      <p>The Enardo™ 2000 Emergency Relief Vent is a versatile solution for tank pressure control, featuring field replaceable components and corrosion resistant coatings. It exceeds the leakage standard, incorporates the Topworx Go Switch Model, and can be used with the Rosemount™ 702 Wireless Discrete Dual Input Transmitter for immediate notification of emergency overpressure events, sticking or failure detection, and compliance with industry standards.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Size range: 4" through 24" Model #2000</li>
 	<li class="li1">Leakage standard: Exceeds 1 SCH @ 90% set point</li>
 	<li class="li1">Field replaceable components: Easy maintenance and replacement</li>
 	<li class="li1">Corrosion resistant coatings: Enhanced durability</li>
 	<li class="li1">Wireless Position Feedback:
<ul class="ul1">
 	<li class="li1">Topworx Go Switch Model:
<ul class="ul1">
 	<li class="li1">SPDT contacts rated 4amp/120 vac, 3 amp / 24 vdc, CSA Cl 1, Div. 1, Groups A-D</li>
</ul>
</li>
 	<li class="li1">Rosemount™ 702 Wireless Discrete Dual Input Transmitter:
<ul class="ul1">
 	<li class="li1">Measurement: Dual Discrete Input, Detects Momentary Inputs &amp; Counts</li>
 	<li class="li1">CSA - Intrinsically Safe</li>
</ul>
</li>
</ul>
</li>
 	<li class="li1">Be notified immediately of emergency overpressure events</li>
 	<li class="li1">Determine ERV sticking or failure when used with a tank pressure transmitter</li>
 	<li class="li1">Meets API Standard 2000, NFPA 30, and OSHA (29 CFR 1910.106) for emergency venting due to fire exposure</li>
</ul>
      <h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Emergency pressure control</li>
	 <li class="li1">Emissions reduction</li>
	 <li class="li1">Energy efficiency and blanket gas savings</li>
	 <li class="li1">Remote monitoring</li>
	 <li class="li1">Compliance with regulations</li>
	 <li class="li1">Enhanced data analysis and reporting</li>
</ul>
      <h1>Links</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/tank-vents-and-hatches/emergency-relief-vents/enardo-model-2000-emergency-relief-vent/" target="_blank" rel="noopener noreferrer">Enardo Model 2000 | Emergency Relief Vents Product Page</a></li>
		<li><a className="text-blue-500" href="https://www3.emersonprocess.com/regulators/Animation/EPRV/EPRV2500_Product_Animation.html" target="_blank" rel="noopener noreferrer">Enardo Model 2500 | Emergency Relief Vents Product Animation</a></li>
      </ul>
    </div>
  )
}
  
export default Enardo2000Smart

