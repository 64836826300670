const Rosemount5408 = () => {
  return (
    <div>
      <p>The Rosemount™ 5408 Non-Contacting Radar Level Transmitter uses enhanced technology and Human Centered Design to deliver accurate, reliable measurements on both liquid and solid materials.</p>
      <h1>Features:</h1>
      <ul>
        <li>Low 12V dc lift - off voltage</li>
        <li>Immune to intermittent power loss</li>
        <li>Unique PTFE seal design eliminates the need for o-rings</li>
        <li>Model tailored to SIS requirements, certified to IEC 61508 SIL 2</li>
        <li>Solid measurement with unique solids algorithm</li>
        <li>Process seal antenna for applications with heavy condensation and aggressive medias<br />Also available with Tri Clamp connection</li>
        <li>3-A certified for hygienic applications</li>
      </ul> 
      <h1>Specifications</h1>
      <ul class="ul1">
 	<li class="li1">Accuracy: Standard: <span className="underline">+</span> 0.08 in. (<span className="underline">+</span> 2 mm)</li>
 	<li class="li1">Ultra accuracy: <span className="underline">+</span> 0.04in. (<span className="underline">+</span> 1mm)</li>
 	<li class="li1">Measurement Range: 131 ft (40 m), 82 ft (25 m) for Rosemount™ 5408:SIS in Safety mode</li>
 	<li class="li1">Operating Pressure: 1450 psi (100 bar)</li>
 	<li class="li1">Operating Temperature:-76 to +482 °F (-60 to +250 °C)</li>
 	<li class="li1">Power Supply: 4-20mA/HART: 12-42.4 VDC (12-30 VDC in Intrinsically Safe installations)</li>
 	<li class="li1">Foundation™ Fieldbus: 9-32 VDC (9-30 VDC in Intrinsically Safe installations and 9-17.5 VDC for FISCO)</li>
 	<li class="li1">Communication Protocol: 4-20mA HART® (2-wire loop-powered), Foundation™ Fieldbus</li>
 	<li class="li1">Certifications/Approvals: ATEX, IECEX, FM, CSA, 3-A, CRN, see full specs for complete list of certifications</li>
 	<li class="li1">Safety: IEC 61508 certified to SIL 2</li>
 	<li class="li1">TÜV tested and WHG approved for overfill prevention</li>
 	<li class="li1">Antenna type: Cone antenna, Process seal antenna, Parabolic antenna</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/measurement-instrumentation/tank-gauging/tank-gauging-radar-level/rosemount-5408-level-transmitter-non-contacting-radar/">Rosemount 5408 Non-Contacting Radar Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default Rosemount5408
