const Rosemount4088 = () => {
  return (
    <div>
      <p>The Rosemount 4088 MultiVariable Transmitter is an industry-leading pressure transmitter designed to meet challenging processes such as oil and gas production, custody transfer and plunger-lift well systems.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Modbus transmitter allows multivariable measurements in combinations of differential and static pressure and temperature</li>
 	<li class="li1">Optimized sensor technology captures large fluctuations in flow rate that commonly occur in oil and gas production applications</li>
 	<li class="li1">LCD display provides data right at the measurement point for further insight into the process</li>
 	<li class="li1">Callendar-Van Dusen sensor matching improves temperature accuracy enabling best-in-class flow calculations</li>
 	<li class="li1">Reverse wiring protection guards the device from incorrect wiring</li>
 	<li class="li1">All-welded, SuperModule™ design offers industry-leading accuracy in harsh environments and applications</li>
 	<li class="li1">Low power consumption helps reduce the size and amount of solar panels and battery banks for cost-efficiency</li>
</ul>
      <h1>Specifications:</h1>
      <ul class="ul1">
 	<li class="li1">Rangedown: Up To 100:1</li>
 	<li class="li1">Communication Protocol: Modbus™, BSAP/MVS</li>
 	<li class="li1">Measurement Range: Up to 2000 psi (138 bar) differential, Up to 6092 psi (420 bar) static</li>
 	<li class="li1">Process Wetted Material: 316L SST, Alloy C-276</li>
 	<li class="li1">Diagnostics: Basic Diagnostics</li>
 	<li class="li1">Certifications/Approvals: NACE™, hazardous location, see full specs for complete list of materials</li>
 	<li class="li1">Enhanced for flow is a ±0.05% of reading specification for increased accuracy</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/measurement-instrumentation/pressure-measurement/pressure-transmitters-and-transducers/rosemount-4088-multivariable-transmitter/" target="_blank" rel="noopener noreferrer">Rosemount 4088 MultiVariable Transmitter Product Page</a>
        </li> 
      </ul>
    </div>
  )
}
  
export default Rosemount4088
