const Rosemount214C = () => {
  return (
    <div>
      <p>The Rosemount™ 214C RTD Temperature Sensor by Spartan Controls is a reliable and precise temperature measurement device suitable for industrial applications. Its Resistance Temperature Detector (RTD) technology ensures accurate readings for effective process control. With its durable construction and compatibility with different environments, it offers reliable performance.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">Temperature range: For wire-wound element, 0.05 °C (0.020) maximum ice-point resistance shift after 1g vibration between 20 and 500 Hz for 150 hours according to IEC 60751:2008</li>
<li> Lead wires: 24 AWG wire, FEP insulated; color coded per IEC 60751</li>
<li>Time constant / Response: 10.8 seconds maximum required to reach 50% sensor response when tested in flowing water according to IEC 60751:2008</li>
<li>Stability: ±0.15 °C (0.059) maximum ice-point resistance shift following 1,000 hours at maximum specified temperature when measured per methods defined in IEC 60751:2008</li>
<li>Insulation resistance: 1000 MΩ minimum insulation resistance when measured at 500 VDC at room temperature</li>
<li>Renewable energy integration</li>
</ul>
<h1>Applications:</h1>
<ul>
 	<li aria-level="1">Carbon capture and storage (CCS)</li>
	 <li aria-level="1">Regulatory compliance and reporting</li>
	 <li aria-level="1">Test & group - Oil & gas</li>
	 <li aria-level="1">Separator temperature sensing</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/measurement-instrumentation/temperature-measurement/temperature-sensors/rosemount-214c-rtd-temperature-sensor/" target="_blank" rel="noopener noreferrer">Rosemount 214C RTD Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default Rosemount214C
