const CrosbyValve = () => {
  return (
    <div>
      <p>J-Series valves provide high quality and dependable overpressure protection for air, gas, steam, vapor, liquid and two-phase applications in one simple design.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">ASME/NB certified capacities for air, water and steam</li>
 	<li class="li1">Full compliance with all major global pressure relief standards including ASME VIII, XIII, API 526/527, EN4126 and PED/CE</li>
 	<li class="li1">Highly customizable to meet most applications</li>
 	<li class="li1">Interchangeable parts to reduce maintenance costs</li>
 	<li class="li1">Full nozzle configuration</li>
 	<li class="li1">Simple and reliable design for easy maintenance</li>
 	<li class="li1">Soft seat options for superior seat tightness</li>
 	<li class="li1">Adjustable blowdown to reduce product loss</li>
 	<li class="li1">Restricted Lift available</li>
 	<li class="li1">Wired or Wireless Relief Monitoring available</li>
</ul>
      <h1>Crosby™ Balanced</h1>
      <ul class="ul1">
 	<li class="li1">20% greater backpressure handling improves reliability during backpressure surges</li>
 	<li class="li1">15% improvement in Kb factor enables sizing of smaller and/or fewer valves</li>
 	<li class="li1">100x more resilient than metal bellows for high-frequency cycling, ensuring safety</li>
 	<li class="li1">Easily convert existing Crosby J-Series with Balanced Diaphragm upgrade kits</li>
</ul>
<h1>Links:</h1><ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/pressure-safety-relief-valves/pressure-relief-safety-valves/crosby-j-series-direct-spring-pressure-relief-valves/">Crosby PRV J-Series Product Page</a></li>
      </ul> 
    </div>
  )
}
  
export default CrosbyValve
