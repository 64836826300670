

const Rosemount2088 = () => {
  return (
    <div>
      <p>The Rosemount 2088 Gauge and Absolute Pressure Transmitter features a Local Operator Interface (LOI) that has easy-to-use menus and built-in configuration buttons, so you can commission the device on the spot without complicated tools. This pressure transmitter is also available with manifolds and remote seals.</p>
<h1>Features:</h1>
<ul className="ul1">
 	<li className="li1">Local Operator Interface (LOI) features straightforward menus and built-in configuration buttons for ease-of-use</li>
 	<li className="li1">Factory-assembled and leak-checked integral manifold and remote seal solutions deliver a quick start-up</li>
 	<li className="li1">Available protocols include 4-20 mA HART and 1-5 Vdc HART Low Power for application flexibility</li>
 	<li className="li1">Lightweight, compact design allows for easy installation</li>
</ul>
      <h1>Specifications:</h1>
      <ul className="ul1">
 	<li className="li1">Performance of 0.065 percent with high accuracy option</li>
 	<li className="li1">Lightweight, compact design for cost-effective installation</li>
 	<li className="li1">Protocols available include 4-20 mA HART® and 1-5 Vdc HART Low Power</li>
 	<li className="li1">Absolute and gauge pressure ranges up to 4,000 psi (276 bar)</li>
 	<li className="li1">Rangeability of 50:1 Reference Accuracy: 0.04 %</li>
 	<li className="li1">Fully configurable LCD display to display process variable, percent of range, and diagnostic messages</li>
 	<li className="li1">Lightweight, compact design enables easy installation</li>
 	<li className="li1">Choice of stainless steel (SST) or Alloy C-276 wetted materials</li>
</ul>
      <h1>Applications:</h1>
      <ul className="ul1">
 	<li className="li1">Pipeline Pressure Monitoring: Accurate pressure measurement for safe operation</li>
 	<li className="li1">Storage Tank Pressure Control: Precise pressure control to optimize capacity</li>
 	<li className="li1">Wellhead Pressure Monitoring: Accurate readings for efficient well pad operation</li>
 	<li className="li1">Oil & Gas Test & Group Separators</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/measurement-instrumentation/pressure-measurement/pressure-transmitters-and-transducers/rosemount-2088-gage-and-absolute-pressure-transmitter/">Rosemount 2088 Gage and Absolute Pressure Transmitter Product Page</a></li>
      </ul>
    </div>
  )
}

export default Rosemount2088