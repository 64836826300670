import React, { useEffect, useState, useRef } from 'react';
import Switch from "react-switch";

//import MouseTracker from './partials/Coordinates'; //keep
//import MouseTracker from './partials/MouseTracker';
import PIP from './partials/PIP';
import ProductViewer from './partials/ProductViewer';
import VideoModal from './VideoModal';

const Cat = ({ data, goNext, goMap, playNext, playOpening, goPrev }) => {
  const [flow, setFlow] = useState(false)
  const [showPV, setShowPV] = useState(false)
  const [pvStart, setPvStart] = useState(0)
  const [showNextVideo, setShowNextVideo] = useState(false)
  const [showInVideo, setShowInVideo] = useState(false)
  const [hideFirstBG, setHideFirstBG] = useState(true)

  const [screenIndex, setScreenIndex] = useState(0)

  const comboMenuOne = useRef(-1)
  const comboMenuTwo = useRef(-1)

  const [modalOpen, setModalOpen] = useState(false);

  /**
   * 
   */
  useEffect(() => {
    setHideFirstBG(true)

    if (playOpening.current && data.in_vid) {
      setShowInVideo(true)
    } else if (data.next_vid && playNext.current) {
      setShowNextVideo(true)
    }

    if (data.combo_menu) {
      comboMenuOne.current = data.combo_menu.menus[0].value1;
      comboMenuTwo.current = data.combo_menu.menus[1].value1;
    }
  }, [data])

  /**
   * 
   */
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**
   * 
   */
  const startNext = () => {
    setScreenIndex(0)
    goNext()
  }

  /**
   * 
   */
  const handleVideoPlay = () => {
    setHideFirstBG(false)
  }

  /**
   * 
   */
  const handleVideoEnd = () => {
    setShowNextVideo(false)
    setShowInVideo(false)
    //document.getElementById('next_vid').classList.add('hidden')
    //document.getElementById('prev_bg').classList.add('hidden')
  };

  /**
   * 
   * @param {*} start 
   */
  const launchProductViewer = (start) => {
    //console.log('launch viewer ' + start);
    setPvStart(start)
    setShowPV(true)
  }

  /**
   * 
   */
  const togglePVVisibility = () => {
    setShowPV(!showPV)
  };

  /**
   * 
   */
  const executeComboMenuSeletion = () => {
    if (comboMenuOne.current != -1 && comboMenuTwo.current != -1) {
      // Calculate the sum
      let sum = comboMenuOne.current + comboMenuTwo.current;

      // Use the sum as the key to access the corresponding value in the 'values' object
      let solution = data.combo_menu.values[sum];

      // Then you can use `solution` as needed, for example:
      setScreenIndex(solution);
    }
  }

  /**
   * 
   */
  return (
    <>
      {!showPV && !showNextVideo && !showInVideo && (
        <>
          {data.video && modalOpen && (
            <VideoModal video={data.video.video} closeModal={closeModal} />
          )}

          {data.screens[screenIndex].products && (
            <div id='products' className='absolute w-full h-full z-30 animate-fade'>
              {data.screens[screenIndex].products.map((product, index) => (
                (product.map && (product.map.coordX > 0)) && (
                  <div
                    key={index}
                    onClick={() => {launchProductViewer(index)}}
                    className='absolute cursor-pointer -translate-x-1/2 -translate-y-1/2 border-2 border-white border-solid rounded-xl hover:bg-darkblue hover:bg-opacity-30 block'
                    style={{
                      width  : product.map.width  + '%',
                      height : product.map.height + '%',
                      top    : product.map.coordY + '%',
                      left   : product.map.coordX + '%'
                    }}
                  ></div>
                )
              ))}
            </div>
          )}

          {/** Top Right */}
          <div className='absolute right-5 top-5 text-white animate-fade flex justify-end items-end z-50 flex-col'>
            <div className='flex justify-end items-center gap-3'>
              <div className='button bg-darkblue bg-opacity-50 hover:bg-opacity-100 text-2xl inline-block mb-5 py-1 px-5 focus:ring-0' onClick={goMap}>Main Menu</div>
              {data.screens[screenIndex].flow && (
                <Switch
                  checked={flow}
                  onChange={() => {setFlow(flow => !flow)}}
                  onColor="#3E69B8"
                  onHandleColor="#ffffff"
                  handleDiameter={30}
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "white",
                        paddingRight: 2
                      }}
                    >
                      Flow
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "white",
                        paddingRight: 2
                      }}
                    >
                      Off
                    </div>
                  }
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={50}
                  width={100}
                  className="react-switch mb-5"
                  id="material-switch"
                />
              )}
            </div>
            <div className='flex justify-end items-center gap-3'>
              <div className='button text-2xl inline-block bg-darkblue bg-opacity-50 hover:bg-opacity-100 pt-1 pb-1 px-5 focus:ring-0' onClick={goPrev}>Prev</div>
              <div className='button text-2xl inline-block bg-darkblue bg-opacity-50 hover:bg-opacity-100 pt-1 pb-1 px-5 focus:ring-0' onClick={startNext}>Next</div>
            </div>
          </div>

          {/* bottom */}
          <div className='p-8 bg-white bg-opacity-70 absolute z-50 bottom-0 left-0 rounded-tr-xl animate-fade'>
            <img src="assets/logo.svg" alt='' className='w-[250px]' />
          </div>

          {/* BGs */}
          {flow && (
            <video 
              controls={false} 
              preload="none" 
              muted 
              className='absolute w-full h-full top-0 right-0 left-0 bottom-0 z-20'
              playsInline
              autoPlay
              loop
              src={data.screens[screenIndex].flow}
            >
            </video>
          )}
          
          {/** Top Left */}
          <PIP circlex={data.circleX} circley={data.circleY} backToStart={goMap} />

          {/* Screen Navigation */}
          {(data.screens.length > 1) && (
            <div className='absolute top-1/2 left-0 transform -translate-y-1/2 z-50 animate-fade'>
              {data.combo_menu ? (
                <div className='z-50 flex flex-col'>
                  {/* "Current: " + data.screens[screenIndex].title */}
                  <div className='bg-white bg-opacity-70 rounded-tr-xl px-5 pb-3 pt-2 flex flex-col mb-5'>
                    <h2 className='mb-5 lg:text-2xl text-xl text-primary font-bold'>Product Grouping</h2>
                    <div 
                      className={`z-50 text-white rounded-xl bg-opacity-80 hover:bg-opacity-100 mb-2 pt-1 pb-1 px-5 mr-2 cursor-pointer lg:text-2xl md:text-xl text-base 
                                  ${(comboMenuOne.current == data.combo_menu.menus[0].value1) ? 'bg-primary' : 'bg-gray-700'}`}
                      onClick={() => {
                        comboMenuOne.current = data.combo_menu.menus[0].value1
                        executeComboMenuSeletion()
                      }}
                    >
                      {data.combo_menu.menus[0].name1}
                    </div>
                    <div 
                      className={`z-50 text-white rounded-xl bg-opacity-80 hover:bg-opacity-100 mb-2 pt-1 pb-1 px-5 mr-2 cursor-pointer lg:text-2xl md:text-xl text-base 
                                  ${(comboMenuOne.current == data.combo_menu.menus[0].value2) ? 'bg-primary' : 'bg-gray-700'}`}
                      onClick={() => {
                        comboMenuOne.current = data.combo_menu.menus[0].value2
                        executeComboMenuSeletion()
                      }}
                    >
                      {data.combo_menu.menus[0].name2}
                    </div>
                  </div>
                  <div className='rounded-br-xl bg-white bg-opacity-70 px-5 py-3 flex flex-col'>
                    <div 
                      className={`z-50 text-white rounded-xl bg-opacity-80 hover:bg-opacity-100 mb-2 pt-1 pb-1 px-5 mr-2 cursor-pointer lg:text-2xl md:text-xl text-base 
                                  ${(comboMenuTwo.current == data.combo_menu.menus[1].value1) ? 'bg-primary' : 'bg-gray-700'}`}
                      onClick={() => {
                        comboMenuTwo.current = data.combo_menu.menus[1].value1
                        executeComboMenuSeletion()
                      }}
                    >
                      {data.combo_menu.menus[1].name1}
                    </div>
                    <div 
                      className={`z-50 text-white rounded-xl bg-opacity-80 hover:bg-opacity-100 mb-2 pt-1 pb-1 px-5 mr-2 cursor-pointer lg:text-2xl md:text-xl text-base 
                                  ${(comboMenuTwo.current == data.combo_menu.menus[1].value2) ? 'bg-primary' : 'bg-gray-700'}`}
                      onClick={() => {
                        comboMenuTwo.current = data.combo_menu.menus[1].value2
                        executeComboMenuSeletion()
                      }}
                    >
                      {data.combo_menu.menus[1].name2}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='px-5 py-3 rounded-tr-xl rounded-br-xl bg-white bg-opacity-70 flex flex-col '>
                  <h2 className='mb-5 lg:text-2xl text-xl text-primary font-bold'>Product Grouping</h2>
                  {data.screens.map((screen, index) => (
                    <div 
                      key={index}
                      className={`z-50 text-white rounded-xl bg-opacity-80 hover:bg-opacity-100 mb-2 pt-1 pb-1 px-5 mr-2 cursor-pointer lg:text-2xl md:text-xl text-base ${(index == screenIndex) ? 'bg-primary' : 'bg-gray-700'}`}
                      onClick={() => {setScreenIndex(index)}}
                    >
                      {data.screens[index].title}
                    </div>
                  ))}
                </div>
              )}

              {data.video && (
                <div className='px-5 py-3 rounded-tr-xl rounded-br-xl bg-white bg-opacity-70 flex flex-col mt-5'>
                  <h2 className='mb-5 lg:text-2xl text-xl text-primary font-bold'>{data.video.title}</h2>
                  <div 
                    className='z-50 text-white rounded-xl bg-opacity-80 hover:bg-opacity-100 mb-2 pt-1 pb-1 px-5 mr-2 cursor-pointer lg:text-2xl md:text-xl text-base bg-gray-700'
                    onClick={openModal}
                  >
                    Click Here for More Info
                  </div>
                </div>
              )}
            </div>
          )}
          
        </>
      )}
      
      {showNextVideo && (
        <>
          {!hideFirstBG && (
            <img src={data.prev_bg} id='prev_bg' alt='prev_bg' className='w-full z-10 absolute h-full top-0 right-0 left-0 bottom-0' />
          )}

          <video 
            controls={false} 
            preload="none" 
            muted 
            className='absolute w-full h-full top-0 right-0 left-0 bottom-0 z-20'
            playsInline
            autoPlay={true}
            id='next_vid'
            src={data.next_vid}
            onEnded={handleVideoEnd}
            onPlay={handleVideoPlay}
          ></video>
        </>
      )}
      {showInVideo && (
        <>
          {!hideFirstBG && (
            <img src="assets/stills/0_MainSiteZoomout_Still___compressed.jpg" id='start_bg' alt='start_bg' className='w-full z-10 absolute h-full top-0 right-0 left-0 bottom-0' />
          )}

          <video 
            controls={false} 
            preload="none" 
            muted 
            className='absolute w-full h-full top-0 right-0 left-0 bottom-0 z-20'
            playsInline
            autoPlay={true}
            id='in_vid'
            src={data.in_vid}
            onEnded={handleVideoEnd}
            onPlay={handleVideoPlay}
          ></video>
        </>
      )}
      
      <img src={data.screens[screenIndex].bg} className='w-full z-0' />

      {/* Uncomment below if you need the mouse tracker 
      <MouseTracker /> */}

      {showPV && (
        <ProductViewer products={data.screens[screenIndex].products} start={pvStart} toggleVisibility={togglePVVisibility} />
      )}
    </>
  )
}

export default Cat