const BettisRTSCompactQuater = () => {
  return (
    <div>
      <p>The Bettiss RTS-Quarter-Turn Fail Last Electric Actuator, is a reliable and precise electric actuator designed for quarter-turn valve control applications. With its compact design, strong torque range, and robust construction, it delivers accurate and consistent quarter-turn movement for efficient valve automation in various industrial settings.</p>
      <h1>Features:</h1>
<ul className="ul1">
    <li className="li1">Local user interface with wireless Bluetooth control capability</li>
    <li className="li1">Highly configurable and intelligent actuator with options for continuous modulation</li>
    <li className="li1">Compact &amp; lightweight with corrosion protection</li>
    <li className="li1">Intelligent and non-intrusive</li>
    <li className="li1">On/off (S2) and continuous modulation (S9)</li>
    <li className="li1">Precise control over the flow of fluids and gases</li>
    <li className="li1">Low power draw, .8mA idle / max 4.6 amp at full speed and torque/thrust</li>
    <li className="li1">Advanced optimization &amp; control:</li>
    <li className="li1">Precise process control - .1%</li>
    <li className="li1">Certified explosion and weatherproof</li>
    <li className="li1">Advanced diagnostic capabilities for real-time feedback on valve performance</li>
    <li className="li1">Protocols: Modbus™, HART™, PROFIBUS™, FOUNDATION Fieldbus™, PROFINET™</li>
    <li className="li1">Bettis RTS CM compact quarter turn electric valve actuator:
        <ul className="ul1">
            <li className="li1">Brushless DC motor technology</li>
            <li className="li1">Rotatable in 90 degree steps with remote mounting option</li>
            <li className="li1">User application available for Android devices</li>
        </ul>
    </li>
    <li className="li1">Energy-efficient operation</li>
    <li className="li1">Robust construction for reliable operation in harsh industrial environments</li>
</ul>
<h1>Applications:</h1>
<ul>
    <li className="p2">Wellhead choke valves: angle body and in-line</li>
    <li className="p2">Quarter turn ball, butterfly and plug valves</li>
</ul>

      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-fq-fail-safe-quarter-turn-electric-actuator/" rel="noopener noreferrer">Bettis RTS Compact Quarter-turn Actuator Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default BettisRTSCompactQuater
