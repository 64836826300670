const EspiritCamera = () => {
  return (
    <div>
      <p>The Esprit® Camera Series offers responsive, high-definition, high-speed positioning capabilities, outstanding weather protection including wiper options, and ease of installation.</p>
      <h1>Features:</h1>
      <ul className="ul1">
    <li className="li1">Three levels of fog removal - improved image quality during bad weather conditions</li>
    <li className="li1">SureVision technology: for advanced low-light and true wide dynamic range performance</li>
    <li className="li1">Optimized image clarity - in high-vibration environments</li>
    <li className="li1">360° continuous pan rotation at 140° variable speed</li>
    <li className="li1">Vivid imaging mode</li>
    <li className="li1">Full HD 1080p 30x optical zoom camera with powerful SureVision 3.0 technology</li>
</ul>

<h1>Applications:</h1>
<ul className="ul1">
    <li className="li1">Security and surveillance</li>
    <li className="li1">Available in standard or pressurized 30x optical zoom module for tough outdoor environments</li>
    <li className="li1">Critical infrastructure protection</li>
    <li className="li1">Border and perimeter security</li>
</ul>

      <h1>Product Link:</h1>
     
          <ul>
            <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/industrial-video/pelco-esprit-hd/">Pelco Esprit Rugged PTZ Camera Product Page</a></li>
          </ul>
        
    </div>
  )
}
  
export default EspiritCamera
