const PointWatchEclipse = () => {
  return (
    <div>
      <p>The PointWatch Eclipse® Model PIRECL IR Hydrocarbon Gas Detector is our most rugged stainless steel, point infrared gas detector. Capable of detecting many other commonly encountered hydrocarbon gases.</p>
      <h1>Features:</h1>
      <ul>
        <li>Performance certified to methane, propane, ethylene, and butane, and is shipped from the factory set and calibrated to one of these gases</li>
        <li>Rugged IP66/67 stainless steel construction and extended interval testing</li>    
        <li>Diagnostic LED provides local alarm and fault annunciation</li>
        <li>Weather baffle with hydrophobic filter option combined with heated sapphire optics reduces nuisance faults from rain and condensation in harsh environments</li> 
        <li>Certified to FM, CSA, IECE and ATEX requirements to detect select gases in as little as 1.5 seconds</li>
      </ul> 
      <h1>Technical:</h1>
      <ul>
        <li>Continuous, fixed monitoring of flammable hydrocarbon gases from 0 to 100% Lower Flammable Limit (LFL)</li>
        <li> Standard device outputs include an electrically isolated 4-20 mA signal with HART communication protocol and RS-485 serial communication</li>
        <li> Serial communication protocols supported include MODBUS</li>
        <li> Global certifications (SIL 2/FM/CSA/ATEX/IECEx)</li>
      </ul>
      <h1>Applications</h1>
      <ul>
        <li>Continuous, fixed monitoring of flammable hydrocarbon gases from 0 to 100% Lower Flammable</li>
        <li> Potential leak sources</li>
        <li> Motors & Drives</li>
        <li> Pumps</li>
        <li> Tanks</li>
      </ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/combustible-gas-detection/det-tronics-ir-detector-pirecl/" target="_blank" rel="noopener noreferrer">PointWatch Eclipse Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default PointWatchEclipse

