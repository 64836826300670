const DettronicsLS2000 = () => {
  return (
    <div>
      <p>The Det-Tronics FlexSight™ LS2000 is designed to meet challenging hydrocarbon gas detection applications. This detector delivers an impressive field of view (FOV), advanced housing design, simplified mounting, and breakthrough alignment tolerance, all in a package designed with ease of maintenance, reliability, and low cost of ownership in mind.</p>
      <h1>Features:</h1>
<ul className="ul1">
    <li className="li1">Outstanding misalignment tolerance for high vibration and wind applications</li>
    <li className="li1">Third-party documented +/-0.8-degree misalignment tolerance (+/- 2m tolerance at 120m range)</li>
    <li className="li1">Performance approved with 95% obscuration</li>
    <li className="li1">Technical:
        <ul className="ul1">
            <li className="li1">Multiple communication protocols, including 4-20 mA, relay, Modbus, HART, FDT®/DTM, and Eagle Quantum Premier® (EQP) safety system</li>
            <li className="li1">Continuous monitoring of combustible hydrocarbon gas concentrations in the range of 0 to 5 LFL-meters, over a distance of 5 to 120 meters</li>
        </ul>
    </li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
    <li className="li1">Early detection of gas leaks</li>
    <li className="li1">Enhanced safety</li>
    <li className="li1">Real-time monitoring</li>
    <li className="li1">Integration with safety systems</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/combustible-gas-detection/det-tronics-flexsight-line-of-sight-infrared-hydrocarbon-gas-detector/">Det-Tronics FlexSight LS2000 Detector Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default DettronicsLS2000
