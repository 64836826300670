const Torqplus = () => {
  return (
    <div>
      <h1>Key Bettis TorqPlus Models :</h1><ul>
<li> EM510M-02-C7-11-483-PKSVA - multi-turn
  <ul>
    <li> 24 VDC, 20 turn gear box, 325 in-lbs, High Resolution PeakTronics Card</li>
  </ul>
  </li>
<li> EM510F-05-C7-11-485-PKSVA - multi-turn<ul>
<li> 24 VDC, 30 turn gear box, 600 in-lbs, High Resolution PeakTronics Card</li></ul></li>
<li> EM500F-30-C7-11-100-PKSVA - quarter turn<ul>
<li> 24 VDC, QTR Turn, 1600 in-lbs, High Resolution PeakTronics Card</li></ul></li>
<li> EM500F-02-C7-02-483-PKSVA - multi-turn<ul>
<li> 115 VAC, 20 turn gear box, 325 in-lbs, High Resolution PeakTronics Card</li></ul></li></ul>
<h1>Features:</h1><ul>
<li> Economical, add 24 vdc and 115 vac</li>
<li> Robust, easy to service, conventional actuator</li>
<li> Compact design with mechanical fail-safe option - 115 VAC only</li></ul>
<h1>Modulation:</h1><ul>
<li> PeakTronics high resolution DHC400/100 digital positioner</li>
<li> Adapts to change in load and actuator conditions</li>
<li> Solid state motor drive with thermal/ overload protection and stall detection-100% Duty cycle</li>
<li> Optional OCM-102 Modbus communication</li></ul>
<h1>Applications:</h1><ul>
<li> Angle Body and In-Line Chokes</li>
<li> Qtr. Turn Valves: Ball, Dampeners, Butterfly valves</li>
<li> ESDV-Qtr. Turn</li></ul>
<h1>Product Link:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-torqplus-electric-valve-actuator/" rel="noopener noreferrer">Bettis TorqPlus Electric Valve Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default Torqplus
