const FloBoss = () => {
  return (
    <div>
      <p>The FloBoss 107 is a single or multi-run flow computer that provides measurement solutions for many natural gas applications that:</p>
      <ul>
        <li>Monitor</li>
        <li>Measure</li>   
        <li>Control equipment in remote locations</li>   
      </ul> 
      <h1>Features:</h1>
      <ul>
        <li>Supports differential pressure elements and pulse meter applications for one-to-four-meter runs.</li>
        <li>Expandable I/O, Six points on the optional CPU assembly and up to six I/O modules.</li>   
        <li>Standard and Extended History archiving.</li>   
        <li>Field-side surge and short-circuit protection.</li> 
        <li>Local storage of monitored, measured, and calculated data.</li>   
        <li>Local control of field equipment, including valves and motors.</li> 
        <li>Local and remote communication capabilities.</li>   
        <li>High levels of data security.</li> 
        <li>Memory back-up by battery and super-capacitor to provide long term data, configuration, and operational integrity when not in service.</li> 
      </ul> 
      <h1>Product Links</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/remote-automation-scada/flow-computers-rtus/flow-computers-rtus/floboss-107-flow-manager/" rel="noopener noreferrer"> FloBoss 107 Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default FloBoss
