const ServiceTrucks = () => {
  return (
    <div>
		<h1 className="text-4xl underline">PRO Upstream Services</h1>
      <h1>Enabling our customers to optimize their assets with our full suite of Field &amp; Shop Service capabilities:</h1>
      <ul>
    <li>Control valve life cycle services</li>
    <li>Isolation valve expanded services</li>
    <li>Protection (PRV/SRV/PRVR) expanded services</li>
    <li>Measurement &amp; instrumentation (MI) services</li>
</ul>

<h1>Onsite Services:</h1>
<ul>
    <li>Mobile on-site capabilities</li>
    <li>Site audits/walkdowns</li>
    <li>Repair, on-site greasing, and servicing of valves</li>
    <li>Commissioning</li>
    <li>Actuator service and repair:</li>
    <li>Electric, pneumatic, hydraulic, &amp; gas over oil</li>
</ul>

<h1>Spartans Services Infrastructure:</h1>
<ul>
    <li>Edmonton service centre
        <ul>
            <li>ASME approved &amp; certified assembly facility</li>
            <li>ABSA approved AQP program for isolation valve modification, welding, machining, and trim changes</li>
        </ul>
    </li>
    <li>Isolation, PSV, PVRV, MI, control repair and testing</li>
    <li>Meter proving stand - up to 8"</li>
    <li>5 regional service centers</li>
    <li>7 mobile on-site service vehicles</li>
</ul>

    </div>
  )
}
  
export default ServiceTrucks
