const FoxMeter = () => {
  return (
    <div>
      <p>Fox Thermal's thermal mass flow meters deliver accurate and reliable flow measurement with advanced technology for various applications. </p>
      <h1>Features:</h1>
      <ul>
        <li>DDC-Sensor™: Robust, non-cantilevered design</li>
        <li>Programmable Contract Time</li>    
        <li>Gas-SelectX®: menu of field selectable gas compositions</li>
        <li>Gross Heating Value and Density Calculations of Gas Mix</li> 
        <li>Standard Data Logger with date/time stamp & 40 24-Hour daily totals</li>
        <li>CAL-V™ Calibration Validation</li> 
        <li>Insertion, Inline, and Remote Styles Measure gas flow rate in SCFM, MCFD, KG/H, & many more</li>
        <li>Wide measurement range: up to 1000:1 turndown; 100:1 typical</li> 
        <li>Two 4-20mA outputs for flow rate or temperature</li>
        <li>Choice of HART or Modbus RTU</li> 
      </ul> 
      <h1>Applications</h1> 
      <ul>
        <li>Measuring the flow of natural gas from wellheads and pipelines</li>
        <li>Monitoring fuel gas flow to engines and turbines</li>
        <li>Measuring the flow of flare gas during flaring operations</li>
        <li>Monitoring the flow of compressed air and other gases used for drilling and fracturing operations</li>
        <li>Measuring the flow of various gases used in the refining process, such as hydrogen and nitrogen</li>
      </ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/measurement-instrumentation/flow-measurement/thermal-mass/">Fox Thermal Gas Mass Flow Meter Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default FoxMeter
