const VaporRecoverySolutions = () => {
  return (
    <div>
      <p>The Fisher T208, Y696, and Type 1290 Regulators are versatile solutions for net zero wellpads. They offer precise pressure control, compatibility with sour gas service, and the ability to operate in low ambient temperatures, contributing to emissions reduction and optimized operations.</p>
      <h1>Features:</h1>
<ul class="ul2">
 	<li class="li1">Low pressure setting & fast speed of response</li>
	 <li class="li1">Accurate control with small lock-up pressure</li>
	 <li class="li1">Sour gas service compatibility</li>
	 <li class="li1">Low ambient temperature construction up to -50C</li>
</ul>
<h1>Specifications:</h1>
<ul class="ul1">
 	<li class="li1">Body size: NPS ¾” up to 12” x 6”</li>
	 <li class="li1">Control pressure range: 0.25 in. w.c. to 10 psig</li>
	 <li class="li1">Maximum inlet pressure: 75 psig</li>
<li class="li1">Flow up to 327,000 SCFH</li>
<li class="li1">Recovery styles: Direct-operated, balanced, pilot operated</li>
</ul>
      <h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Pressure control in wellpad tanks</li>
 	<li class="li1">Regulation of gas flow in vapor recovery units (VRUs)</li>
 	<li class="li1">Fluid blanketing in tanks</li>
 	<li class="li1">Emissions reduction</li>
 	<li class="li1">Sour gas service compatibility</li>
 	<li class="li1">Low ambient temperature operation</li>
 	<li class="li1">Wellpad optimization</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/regulators/tank-blanketing/fisher-type-y696-vapor-recovery-regulator/" rel="noopener noreferrer">Vapour Recovery Solutions Product Page</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/Type%201290%20Product%20Animation/Type%201290%20Product%20Animation.html" rel="noopener noreferrer">Fisher Type 1290 Regulator Product Animation</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/regulators/Animation/T208/T208%20Product%20Animation.html" rel="noopener noreferrer">Fisher T208 Series Product Animation</a></li>
      </ul>
    </div>
  )
}
  
export default VaporRecoverySolutions
