const GVSValve = () => {
  return (
    <div>
      <p>With a focus on reliability, efficiency, and safety, these Trunnion Ball Valves epitomize value by ensuring optimal fluid control and operational integrity, empowering businesses to achieve operational excellence and maximize productivity with confidence.</p>
      <h1>Features:</h1>
      <ul>
    <li>
        GVS trunnion ball valves
        <ul>
            <li>SPE, DIB-1, DIB-2, EDS</li>
        </ul>
    </li>
    <li>
        Ball valves sizes & design:
        <ul>
            <li>2" - 42"</li> 
            <li>Ansi 150#-2500#</li>    
            <li>API 6D, API 608</li> 
        </ul>
    </li>
    <li>
        GVS soft seated:
        <ul>
            <li>Body: LF2(A350)/A105, WCB(A216), LCC(A352), 316SS</li>
        </ul>
    </li>
    <li>Trim (ball/stem): LF2 w/3Mil ENC, 316SS</li>
    <li>Recessed Ball available</li>
    <li>Seats+: RTFE (glass filled), Viton GLT, RTFE (PEEK filled), PEEK, metal seat for abrasive process +240°C, metal seat for high temperature process +350°C</li>
</ul>

<h1>Applications:</h1>
<ul>
    <li>Upstream, transmission pipelines, pumping, compression</li>
    <li>Re-injection Units, Terminals, Pig Traps, Measuring Stations, Cryogenic-LNG</li>    
    <li>High temperature</li> 
    <li>Abrasive services</li>
</ul>

      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/gvs-trunnion-mounted-severe-service-soft-seated-ball-valve/">GVS Trunnion Mounted Severe Service Soft Seated Ball Valve</a></li>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/gvs-trunnion-mounted-soft-seated-ball-valves/">GVS Trunnion Mounted Soft Seated Ball Valves</a></li>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/gvs-trunnion-metal-seated-ball-valve/">GVS Trunnion Metal Seated Ball Valve</a></li>
      </ul>
    </div>
  )
}
  
export default GVSValve
