const FB3000 = () => {
  return (
    <div>
      <p>The FB3000 Remote Terminal Unit (RTU) is a powerful controller designed for the oil and gas industry, offering scalability and expanded data capacity. It provides reliable automation and monitoring solutions for demanding operations in this sector.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">API, AGA, GPA and ISO calculations built into the base firmware adjust for operating conditions to give reliable, accurate measurement of oil and/or gas fluids.</li>
	 <li aria-level="1"> A flexible architecture that ranges from low power applications to high density I/0 and distributed I/0 all in one platform.</li>
	 <li aria-level="1"> The extension chassis provides scalability for larger wellpad operators that can now use a single RTU with more I/0 capability.</li>
	 <li aria-level="1"> Firmware-based PIDs provide dual loop control with switchover logic while Effects, Math and Action Blocks offer simple programming functionality.</li>
	 <li aria-level="1">Open-standard DNP3 protocol offers authenticated communication between the client and server that is reliable, flexible and secure.</li>
</ul>
	  <p>
	  ProductionManager EDGE® suite of applications:<br />
	  Designed to provide standardized yet flexible solutions to common processes in the oil & gas production field.
	  </p>
	  <p>
	  ProductionManager™ Well Testing :<br />
allows you to maintain accurate allocation measurement while achieving facility cost reduction goals. This application facilitates scheduled, automated well testing or manual well test operations.
	  </p>
	  <ul>
		<li>Manual/ Automated Valve Support</li>
		<li> Scheduling</li>
		<li> Comprehensive Reporting Process</li>
	  </ul>
	  <p>
	  ProductionManager™ Well Optimization:<br />
allows you to optimize production based on industry standard methods. This application provides your operation with continuous, real-time optimization of production, as well as surface and downhole analytics.
	  </p>
	  <ul>
		<li>Plunger Lift Optimization</li>
		<li> Gas Lift Optimization</li>
		<li>Wellhead Analytics</li>
	  </ul>
      <h1>Product Link:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/remote-automation-scada/flow-computers-rtus/flow-computers-rtus/fb3000-remote-terminal-unit-rtu/">FB3000 Remote Terminal Unit Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default FB3000
