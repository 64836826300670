const Enardo2001 = () => {
  return (
    <div>
      <p>The Enardo™ 2000/2100 & 2500 Series Tank Vents provide reliable emergency pressure control and relief for storage tanks, ensuring safe and efficient operations. With features such as high-capacity vacuum flow and field replaceable components, these tank vents offer durability, flexibility, and compliance with industry standards.</p>
      <h1>Features:</h1>
      <ul>
      <li>Emergency pressure control for storage tanks</li>
      <li> Emergency pressure relief opening for overpressures</li>
      <li> High-capacity vacuum flow for emergency pump-out of product</li>
      <li> Field replaceable components for easy maintenance</li>
      <li> Corrosion resistant coatings for durability</li>
      <li> Certified flow capacities per API standards</li>
      <li> Available with various flange options</li>
      <li> Suitable for different tank sizes</li>

<li>Specifications:
<ul>
<li> Sizes 16" through 24" Model #2100 & #2500</li>
<li> Sizes 4" through 24" Model #2000</li>
<li> Exceeds Leakage Standard of 1 SCH @ 90% Set Point</li>
<li> Field Replaceable Components</li>
<li> Corrosion Resistant Coatings</li>
<li> Certified Flow Capacities Per API</li>
<li> Available with ANSI, DIN and JIS Flanges</li>
</ul>
</li>
      </ul> 
      <h1>Applications:</h1>
      <ul>
        <li>Tank pressure control</li>
<li> Emissions reduction</li>
<li> Compliance with regulations</li>
      </ul> 
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/tank-vents-and-hatches/emergency-relief-vents/enardo-model-2000-emergency-relief-vent/" rel="noopener noreferrer">Enardo Model 2000 Emergency Relief Vent Product Page</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/regulators/Animation/EPRV/EPRV2500_Product_Animation.html" rel="noopener noreferrer">Enardo Model 2500 Emergency Relief Vent Product Animation</a></li>
      </ul>
    </div>
  )
}
  
export default Enardo2001
