const BettisRTSMulti = () => {
  return (
    <div>
      <p>The Bettis RTS-CL Compact Multi-turn Electric Actuator, is a reliable and precise electric actuator designed for rotary valve control applications. With its compact design, strong torque range, and robust construction, it delivers accurate and consistent rotary movement for efficient valve automation in various industrial settings.</p>
      <h1>Features:</h1>
<ul className="ul1">
<li>Local user interface with wireless bluetooth control capability</li>
<li>Highly configurable and intelligent actuator with options for continuous modulation (S9) and on/off (S2)</li>
<li>Compact & lightweight with corrosion protection</li>
<li>Intelligent and non-intrusive</li>
<li>Monitoring and diagnostics</li>
<li>Designed for long-lasting performance.</li>
<li>Easy integration into existing control systems</li>
<li>Enhanced performance and control capabilities for valve automation</li>
<li>Certified explosion and weatherproof</li>
<li>Protocols: Modbus™, HART™, PROFIBUS™, FOUNDATION Fieldbus™, PROFINET™</li>
<li>Betis RTS CM Compact Multi-turn Electric Valve Actuator:
<ul>
<li>Adjustable speed (2 sec ± 54 sec)</li>
<li>Brushless DC motor technology</li>
<li>Rotatable in 90 degree steps with remote mounting option</li>
<li>User application available for android devices</li>
</ul></li>
</ul>
<h1>Applications:</h1>
<ul>
    <li>Wellhead choke valves - angle body and in-line</li>
    <li>Plunger/gas Lift - angle body chokes</li>
    <li>Separator inlet and back pressure control</li>
</ul>
<h1>Product Link:</h1><ul>
      <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-cm-compact-multi-turn-electric-actuator/" target="_blank" rel="noopener noreferrer">Bettis RTS Multi-Turn Product Page</a></li>
	  <li><a className="text-blue-500" href="https://www.emerson.com/en-us/catalog/emerson-dcmlink-software" target="_blank" rel="noopener noreferrer">Asset monitoring - DCM Link Software</a></li>
    </ul>
    </div>
  )
}
  
export default BettisRTSMulti
