import Placeholder from "./content/Placeholder"
import FisherL2e from "./content/FisherL2e"
import Fisher200L from "./content/Fisher200L"
import Rosemount3051S from "./content/Rosemount3051S"
import BettisRTS from "./content/BettisRTS"
import LCOCrossfire from "./content/LCOCrossfire"
import Boreal3 from "./content/Boreal3"
import Rosemount2088 from "./content/Rosemount2088"
import BettisHydra from "./content/BettisHydra"
import BettisFailSafe from "./content/BettisFailSafe"
import PenberthyGauges from "./content/PenberthyGauges"
import FB1200 from "./content/FB1200"
import RoxarSand from "./content/RoxarSand"
import BettisRTSMulti from "./content/BettisRTSMulti"
import BettisRTSQuarter from "./content/BettisRTSQuarter"
import FB3000 from "./content/FB3000"
import Rosemount928 from "./content/Rosemount928"
import DettronicsX3301 from "./content/DettronicsX3301"
import DettronicsLS2000 from "./content/DettronicsLS2000"
import EnardoES665 from "./content/EnardoES665"
import Enardo2000 from "./content/Enardo2000"
import EnardoES850 from "./content/EnardoES850"
import EnardoES950 from "./content/EnardoES950"
import FoxMeter from "./content/FoxMeter"
import SpectraCamera from "./content/SpectraCamera"
import EspiritCamera from "./content/EspiritCamera"
import EnardoFlame from "./content/EnardoFlame"
import GVSValve from "./content/GVSValve"
import DHV from "./content/DHV"
import PointWatchEclipse from "./content/PointWatchEclipse"
import DetTronicsFlexVU from "./content/DetTronicsFlexVU"
import Rosemount214C from "./content/Rosemount214C"
import MicroMotion from "./content/MicroMotion"
import CrosbyValve from "./content/CrosbyValve"
import Rosemount5300 from "./content/Rosemount5300"
import Rosemount5408 from "./content/Rosemount5408"
import ServiceTrucks from "./content/ServiceTrucks"
import Empowerment from "./content/Empowerment"
import Rosemount3051 from "./content/Rosemount3051"
import Rosemount4088 from "./content/Rosemount4088"
import FisherHPT from "./content/FisherHPT"
import FisherET from "./content/FisherET"
import BettisQuarterTurnFailSafe from "./content/BettisQuarterTurnFailSafe"
import DetTronicsGT3000 from "./content/DetTronicsGT3000"
import Enardo2000Smart from "./content/Enardo2000Smart"
import TankProtectionSolutions from "./content/TankProtectionSolutions"
import TankBlanketingSolutions from "./content/TankBlanketingSolutions"
import VaporRecoverySolutions from "./content/VaporRecoverySolutions"
import Enardo2001 from "./content/Enardo2001"
import SmartTank from "./content/SmartTank"
import FloBoss from "./content/FloBoss"
import BettisRTSCompactQuater from "./content/BettisRTSCompactQuater"
import BettisFL from "./content/BettisFL"
import TankProtectionSolutionsTwo from "./content/TankProtectionSolutionsTwo"
import BettisTorqPlus from "./content/BettisTorqPlus"
import Torqplus from "./content/torqplus"
import Torqplusfail from "./content/torqplusfail"

export const product_images = [
  'assets/products/00_Bettis_RTS_Electric_Acutator_Features_Fail_Last.png',
  'assets/products/01_Bettis_RTS_Compact_Quarter_turn.png', 
  'assets/products/02_Bettis_RTS_Compact_Multi_turn.png', 
  'assets/products/03_Bettis_RTS_Compact_Linear.png', 
  'assets/products/04_Bettis_TorqPlus_Multi_turn_Quarter_Turn.png',
  'assets/products/05_Bettis_TorqPlus_Linear.png',
  'assets/products/06_Bettis_SCE_Quarter_Turn.png',
  'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
  'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
  'assets/products/09_Bettis_TorqPlus_Quarter_Turn_Fail_Safe.png',
  'assets/products/10_Bettis_Self_Contained_Hydraulic_System.png',
  'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png', 
  'assets/products/12_Fisher_Easy_Drive_RPU_Fail_Safe.png',
  'assets/products/13_Fisher_L2e_Level_Controller.png',
  'assets/products/14_FB1200_Multi_Run_Explosion_Proof_Flow.png', 
  'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png', 
  'assets/products/15_Penberthy_Level_Gauges_Gauge_Cocks.png',
  'assets/products/16_Crosby_Pressure_Relief_Valves.png',
  'assets/products/17_DHV_Valve_Line.png', 
  'assets/products/18_GVS_Valve_Lines.png',
  'assets/products/19_Rosemount_3051_Pressure_Transmitter.png',
  'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
  'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
  'assets/products/22_Rosemount_2051_In_Line_Pressure_Transmitter.png',
  'assets/products/23_Rosemount_928_Wireless_Gas_Monitor_Detection.png',
  'assets/products/24_Micro_Motion_Coriolis_Meters.png',
  'assets/products/25_LCO_CROSSFIRE_Chemical_Pump.png',
  'assets/products/26_Enardo_ES_665_Thief_Hatch.png',
  'assets/products/27_Enardo_ES_950_Series_Pressure_Vacuum_Relief_Valve.png',
  'assets/products/28_Smart_Enardo_ES_950_Series_Pressure_Vacuum_Relief_Valve.png',
  'assets/products/29_Smart_Emergency_Pressure_Relief_Vent.png',
  'assets/products/30_Spectra_Enhanced_7_Series_PTZ_Dome_Camera.png',
  'assets/products/31_Esprit_Enhanced_Series_Rugged_PTZ_Camera.png',
  'assets/products/32_Boreal_Gas_Finder_3_Open_Path_TLD_Analyzer.png',
  'assets/products/33_Det_Tronics_Open_Path_LS2000_Detector.png',
  'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
  'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
  'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
  'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png', 
  'assets/products/38_FloBoss_107.png',
  'assets/products/39_FB1200_Multi_Run_Explosion_Proof_Flow_Computer_for_Gas_and_Liquids.png',
  'assets/products/40_FB3000_Remote_Terminal_Unit_(RTU).png',
  'assets/products/41_Smart_Enardo_ES_850_Series_Pressure_Vacuum_Relief_Valve.png',
  'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
  'assets/products/43_Rosemount_5408_Radar_Level.png',
  'assets/products/44_Rosemount_214C_RTD_Temperature_Sensor.png',
  'assets/products/45_Roxar_SAM_Acoustic_Sand_Monitor.png',
  'assets/products/46_Enardo_Flame_Arrestors.png',
  'assets/products/47_Empowerment_Services.png',
  'assets/products/48_Spartan_MRP_Upstream_Services.png',
  'assets/products/49_Fisher_HPT_Control_Valve.png',
  'assets/products/50_Fox_thermal_Meter.png',
  'assets/products/51_Fisher_T208_Y696_1290_Recovery_Solutions.png',
  'assets/products/52_Fisher_T208_Y696_1290_Recovery_Solutions.png',
  'assets/products/53_Enardo_2000_2100_2500_Series.png',
  'assets/products/54_Smart_Enardo_ES_660_665_Wireless_Thief_Hatch.png',
  'assets/products/55_Smart_Tank_Blanketing_Vapor_Recovery_Regulator.png',
  'assets/products/56_Tank_Protection_Solutions_Enardo_Enhanced_Sealing_Pressure_Vacuum_Relief_Valve.png',
  'assets/products/57_Tank_Protection_Solutions_Enardo_Enhanced_Sealing_Pressure_Vacuum_Relief_Valve_copy.png',
  'assets/products/58_Fisher_ET_Control_Valve.png',
  'assets/products/Remote_power_interior.jpg',
  'assets/products/rtu.jpg',
  'assets/products/sand_separator_and_automated_choke_valve.jpg',
  'assets/products/service_slide.jpg',
];

export const images = [
  'assets/stills/0_MainSiteZoomout_Still___compressed.jpg',
  'assets/stills/1_ProductionSkid_Still___compressed.jpg',
  'assets/stills/2_Compact_Linear_SCHS_Toggle_Still___compressed.jpg',
  'assets/stills/2_Compact_Multi_Turn_&_Quarter_Turn_Fail_Safe_Toggle_Still___compressed.jpg',
  'assets/stills/2_Compact_MultiTurn_SCHS_Toggle_Still___compressed.jpg',
  'assets/stills/2_Wellhead_Default_Still___compressed.jpg',
  'assets/stills/3_SandSeperator_Still___compressed.jpg',
  'assets/stills/4_Metering_Skid_Default_Compressed.jpg',
  'assets/stills/4_Metering_Skid_Rosemount_2088_Toggle_Still_Compressed.jpg',
  'assets/stills/4_Metering_Skid_Rosemount_3051S_Toggle_Still_Compressed.jpg',
  'assets/stills/5_Empowerment_Still___compressed.jpg',
  'assets/stills/6_Vert_Seperator_Still___compressed.jpg',
  'assets/stills/7_Test_Seperator_Still___compressed.jpg',
  'assets/stills/8_Group_Seperator_3051S_Toggle_Still___compressed.jpg',
  'assets/stills/8_Group_Seperator_FB12000_Toggle_Still___compressed.jpg',
  'assets/stills/8_GroupSeperator_2088_Defualt____compressed.jpg',
  'assets/stills/9_Group_Seperator_Back_5301_Toggle_Still_Compressed.jpg',
  'assets/stills/9_Group_Seperator_Back_Default____compressed.jpg',
  'assets/stills/10_Trucks_Still___compressed.jpg',
  'assets/stills/11_TankProtection_Zoomout_Still___compressed.jpg',
  'assets/stills/12_TankProtection_850_Default_Still___compressed.jpg',
  'assets/stills/12_TankProtection_950_Toggle_Still_V2_Compressed.jpg',
  'assets/stills/13_FlareStack_Cam_Default_Still___compressed.jpg',
  'assets/stills/13_FlareStack_DHV_Toggle_Still_Compressed.jpg',
  'assets/stills/13_FlareStack_securitycamera_Toggle_Still_Compressed.jpg',
  'assets/stills/4_Metering_Skid_Default.jpg',
  'assets/stills/13_Flare_DomeCam_StriaghtBarValve_Toggle.jpg'
];

const flows = [
  'assets/flows/1_Site_Flowlines_Update_V2_1.webm',
  'assets/flows/2_Production_Flowlines_Updated__2.webm',
  'assets/flows/3_Wellhead_Compact_Linear_Schs_Flowlines_Updated_4.webm',
  'assets/flows/3_Wellhead_Compact_Multi_Turn_&_Quarter_Turn_Fail_Safe_Updated_3.webm',
  'assets/flows/3_Wellhead_Compact_Multiturn_Schs_Updated_5.webm',
  'assets/flows/3_Wellhead_Flowlines_Updated_6.webm',
  'assets/flows/4_Sandseperator_Flowlines_Updated_7.webm',
  'assets/flows/5_Meteringskid_2088_Flowlines_Updated_8.webm',
  'assets/flows/5_Meteringskid_3051S_Flowlines_Updated_9.webm',
  'assets/flows/5_Meteringskid_Flowlines_Updated_10.webm',
  'assets/flows/6_Test_Seperatorflowlines_Updated_11.webm',
  'assets/flows/6_Vert_Seperatorflowlines_Toggleupdatedv2_12.webm',
  'assets/flows/6_Vert_Seperatorflowlines_Toggleupdatedv3_13.webm',
  'assets/flows/7_Groupseperator__2088_Flowlines_Updated_14.webm',
  'assets/flows/7_Groupseperator__3051Stoggle_Flowlines_Updated_15.webm',
  'assets/flows/7_Groupseperator__4088Toggle_Flowlines_Updated_16.webm',
  'assets/flows/7_Groupseperator__Fb12000toggle_Flowlines_Updated_17.webm',
  'assets/flows/8_Groupseperatorback_5301Toggle_Flowlines_Updated_18.webm',
  'assets/flows/8_Groupseperatorback_Flowlines_Updatedv2_19.webm'
]

export const videos = [
  'assets/flythru/1_Intro_to_Production.webm', 
  'assets/flythru/2_Production_to_Wellhead.webm', 
  'assets/flythru/3_Wellhead_to_SandSeperator.webm', 
  'assets/flythru/4_SandSeperator_to_MeteringSkid.webm', 
  'assets/flythru/5_Metering_to_Empowerment.webm', 
  'assets/flythru/6_Empowerment_to_TestSeperator.webm', 
  'assets/flythru/7_TestSeperator_to_GroupSeperator.webm', 
  'assets/flythru/8_GroupSeperator_to_BackSide.webm', 
  'assets/flythru/9_BackSide_to_Trucks.webm', 
  'assets/flythru/10_Trucks_to_TankProtectionZoomOut.webm', 
  'assets/flythru/11_TankProtectionZoomout_to_TankProtectionCloseup.webm', 
  'assets/flythru/12_TankProctectionCloseup_to_Flaire.webm', 
  'assets/flythru/13_Flare_to_SiteOutro.webm',
  'assets/iso/1_Production_Skid_ISO2.webm', 
  'assets/iso/2_Wellhead_Iso2.webm', 
  'assets/iso/3_SandSeperator_ISO2.webm', 
  'assets/iso/4_Metering_Skid_ISOV32.webm', 
  'assets/iso/5_Empowerment_Box_ISO2.webm', 
  'assets/iso/6_Vertical_Skid_ISO2.webm', 
  'assets/iso/7_Test_Seperator_Skid_ISO2.webm', 
  'assets/iso/8_Group_Seperator_ISO2.webm', 
  'assets/iso/9_Group_SeperatorBackSide_ISO2.webm', 
  'assets/iso/10_Trucks_ISO2.webm', 
  'assets/iso/11_TankProtection_Zoomout_ISO2.webm', 
  'assets/iso/12_TankProtection_ISO2.webm', 
  'assets/iso/13_Flare_Camera_ISO2.webm',
  'assets/products/spartan-tank-program.webm'
];

export const datajson = {
  prodskid : {
    title    : "Production Skid",
    prev     : "flaire",
    next     : 'wellhead',
    in_vid   : "assets/iso/1_Production_Skid_ISO2.webm",
    prev_bg  : "assets/stills/0_MainSiteZoomout_Still___compressed.jpg",
    startL   : true, 
    circleX  : 30,
    circleY  : 15,
    mapx     : 441,
    mapy     : 150,
    linex    : 305,
    liney    : 330,
    lineoff  : 20,
    circleOX : -8,
    circleOY : 10,
    mapclass : 'prod-skid bottom',
    screens : [
      {
        bg       : "assets/stills/1_ProductionSkid_Still___compressed.jpg",
        flow     : "assets/flows/2_Production_Flowlines_Updated__2.webm",
        products : [
          {
            map : {
              coordX : 48,
              coordY : 27,
              width  : 4,
              height : 9,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 55,
              coordY : 44,
              width  : 8,
              height : 12,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 77,
              coordY : 23,
              width  : 8,
              height : 10,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 71,
              coordY : 42,
              width  : 7,
              height : 16,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 72,
              coordY : 56,
              width  : 7,
              height : 10,
            },
            display : {
              title1  : 'LCO CROSSFIRE',
              title2  : 'Chemical Injection Pump',
              image   : 'assets/products/25_LCO_CROSSFIRE_Chemical_Pump.png',
              content : <LCOCrossfire />
            }
          },
          {
            map : {
              coordX : 49,
              coordY : 63,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            map : {
              coordX : 58,
              coordY : 28,
              width  : 6,
              height : 16,
            },
            display : {
              title1  : 'Penberthy Level Gauges &',
              title2  : 'Gauge Cocks',
              image   : 'assets/products/15_Penberthy_Level_Gauges_Gauge_Cocks.png',
              content : <PenberthyGauges />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      }
    ],
  },
  wellhead : {
    title    : "Wellhead",
    prev     : "prodskid",
    next     : "sand",
    next_vid : "assets/flythru/2_Production_to_Wellhead.webm",
    prev_bg  : "assets/stills/1_ProductionSkid_Still___compressed.jpg",
    in_vid   : 'assets/iso/2_Wellhead_Iso2.webm',
    circleX  : 25,
    circleY  : 30,
    mapx     : 700,
    mapy     : 183,
    linex    : 537,
    liney    : 295,
    lineoff  : 80,
    circleOX : -7,
    circleOY : 9,
    mapclass : 'bottom',
    combo_menu: {
      menus : [
        {
          name1  : 'Bettis Compact Linear',
          value1 : 0,
          name2  : 'Bettis Compact Multi Turn',
          value2 : 1
        },
        {
          name1  : 'Bettis Quarter Turn Fail Safe',
          value1 : 5,
          name2  : 'Bettis Self Contained Hyd',
          value2 : 10
        }
      ],
      values : {
        5  : 0,
        6  : 1,
        10 : 2,
        11 : 3
      }
    },
    screens  : [
      {
        bg       : "assets/stills/2_Wellhead_Default_Still___compressed.jpg",
        flow     : "assets/flows/3_Wellhead_Flowlines_Updated_6.webm",
        title    : "Default",
        products : [
          {
            display : {
              title1  : 'Bettis Pressure Guard Hydraulic',
              title2  : 'Emergency Shutdown System',
              image   : 'assets/products/10_Bettis_Self_Contained_Hydraulic_System.png',
              content : <BettisHydra />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 62.5,
              coordY : 60,
              width  : 8,
              height : 16,
            },
            display : {
              title1  : 'Boreal',
              title2  : 'GasFinder3-DC (Dual Channel)',
              image   : 'assets/products/32_Boreal_Gas_Finder_3_Open_Path_TLD_Analyzer.png',
              content : <Boreal3 />
            }
          },          
          {
            map : {
              coordX : 81,
              coordY : 68,
              width  : 5,
              height : 10,
            },
            display : {
              title1  : 'Rosemount<sup>TM</sup> 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />
            }
          },
          {
            display : {
              title1  : 'Bettis RTS CL (Multi-turn)',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/02_Bettis_RTS_Compact_Multi_turn.png',
              content : <BettisRTSMulti />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50, //percentages for all
              coordY : 23,
              width  : 15,
              height : 24,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS Compact Quarter-turn',
              title2  : 'Fail last Electric Valve Actuator',
              image   : 'assets/products/01_Bettis_RTS_Compact_Quarter_turn.png',
              content : <BettisRTSCompactQuater />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS FL Linear Fail-Safe',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
              content : <BettisFL />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 71,
              coordY : 29,
              width  : 12,
              height : 13,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric Valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/2_Compact_Multi_Turn_&_Quarter_Turn_Fail_Safe_Toggle_Still___compressed.jpg",
        flow     : "assets/flows/3_Wellhead_Compact_Multi_Turn_&_Quarter_Turn_Fail_Safe_Updated_3.webm",
        title    : "Self Contained Hydraulic System",
        products : [
          {
            display : {
              title1  : 'Bettis Pressure Guard Hydraulic',
              title2  : 'Emergency Shutdown System',
              image   : 'assets/products/10_Bettis_Self_Contained_Hydraulic_System.png',
              content : <BettisHydra />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 62.5,
              coordY : 60,
              width  : 8,
              height : 16,
            },
            display : {
              title1  : 'Boreal',
              title2  : 'GasFinder3-DC (Dual Channel)',
              image   : 'assets/products/32_Boreal_Gas_Finder_3_Open_Path_TLD_Analyzer.png',
              content : <Boreal3 />
            }
          },
          {
            map : {
              coordX : 81,
              coordY : 68,
              width  : 5,
              height : 10,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50, //percentages for all
              coordY : 25,
              width  : 12,
              height : 18,
            },
            display : {
              title1  : 'Bettis RTS CL (Multi-turn)',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/02_Bettis_RTS_Compact_Multi_turn.png',
              content : <BettisRTSMulti />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS Compact Quarter-turn',
              title2  : 'Fail last Electric Valve Actuator',
              image   : 'assets/products/01_Bettis_RTS_Compact_Quarter_turn.png',
              content : <BettisRTSCompactQuater />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS FL Linear Fail-Safe',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
              content : <BettisFL />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 71,
              coordY : 29,
              width  : 12,
              height : 13,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric Valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/2_Compact_Linear_SCHS_Toggle_Still___compressed.jpg",
        flow     : "assets/flows/3_Wellhead_Compact_Linear_Schs_Flowlines_Updated_4.webm",
        title    : "Compact Multi Turn & Quarter Turn",
        products : [
          {
            map : {
              coordX : 73,
              coordY : 29,
              width  : 18,
              height : 15,
            },
            display : {
              title1  : 'Bettis Pressure Guard Hydraulic',
              title2  : 'Emergency Shutdown System',
              image   : 'assets/products/10_Bettis_Self_Contained_Hydraulic_System.png',
              content : <BettisHydra />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 62.5,
              coordY : 60,
              width  : 8,
              height : 16,
            },
            display : {
              title1  : 'Boreal',
              title2  : 'GasFinder3-DC (Dual Channel)',
              image   : 'assets/products/32_Boreal_Gas_Finder_3_Open_Path_TLD_Analyzer.png',
              content : <Boreal3 />
            }
          },
          {
            map : {
              coordX : 81,
              coordY : 68,
              width  : 5,
              height : 10,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS CL (Multi-turn)',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/02_Bettis_RTS_Compact_Multi_turn.png',
              content : <BettisRTSMulti />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50, //percentages for all
              coordY : 25,
              width  : 12,
              height : 18,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS Compact Quarter-turn',
              title2  : 'Fail last Electric Valve Actuator',
              image   : 'assets/products/01_Bettis_RTS_Compact_Quarter_turn.png',
              content : <BettisRTSCompactQuater />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS FL Linear Fail-Safe',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
              content : <BettisFL />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric Valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/2_Compact_MultiTurn_SCHS_Toggle_Still___compressed.jpg",
        flow     : "assets/flows/3_Wellhead_Compact_Multiturn_Schs_Updated_5.webm",
        title    : "Compact Multiturn",
        products : [
          {
            map : {
              coordX : 73,
              coordY : 29,
              width  : 18,
              height : 15,
            },
            display : {
              title1  : 'Bettis Pressure Guard Hydraulic',
              title2  : 'Emergency Shutdown System',
              image   : 'assets/products/10_Bettis_Self_Contained_Hydraulic_System.png',
              content : <BettisHydra />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 62.5,
              coordY : 60,
              width  : 8,
              height : 16,
            },
            display : {
              title1  : 'Boreal',
              title2  : 'GasFinder3-DC (Dual Channel)',
              image   : 'assets/products/32_Boreal_Gas_Finder_3_Open_Path_TLD_Analyzer.png',
              content : <Boreal3 />
            }
          },
          {
            map : {
              coordX : 81,
              coordY : 68,
              width  : 5,
              height : 10,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50, //percentages for all
              coordY : 25,
              width  : 12,
              height : 18,
            },
            display : {
              title1  : 'Bettis RTS CL (Multi-turn)',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/02_Bettis_RTS_Compact_Multi_turn.png',
              content : <BettisRTSMulti />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS Compact Quarter-turn',
              title2  : 'Fail last Electric Valve Actuator',
              image   : 'assets/products/01_Bettis_RTS_Compact_Quarter_turn.png',
              content : <BettisRTSCompactQuater />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS FL Linear Fail-Safe',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
              content : <BettisFL />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric Valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      }
    ],
  },
  sand : {
    title    : "Sand Separator",
    prev     : "wellhead",
    next     : "meterskid",
    next_vid : "assets/flythru/3_Wellhead_to_SandSeperator.webm",
    prev_bg  : "assets/stills/4_Metering_Skid_Default_Compressed.jpg",
    in_vid   : 'assets/iso/3_SandSeperator_ISO2.webm',
    circleX  : 27,
    circleY  : 33,
    mapx     : 664,
    mapy     : 379,
    linex    : 611,
    liney    : 300,
    lineoff  : 30,
    circleOX : -3,
    circleOY : -12,
    mapclass : 'bottom',
    screens  : [
      {
        bg       : "assets/stills/3_SandSeperator_Still___compressed.jpg",
        flow     : "assets/flows/4_Sandseperator_Flowlines_Updated_7.webm",
        products : [
          {
            map : {
              coordX : 61, //percentages for all
              coordY : 42,
              width  : 7,
              height : 10,
            },
            display : {
              title1  : 'Roxar™ SAM',
              title2  : 'Acoustic Sand Monitor',
              image   : 'assets/products/45_Roxar_SAM_Acoustic_Sand_Monitor.png',
              content : <RoxarSand />,
              emlogo  : true
            }
          },
        ]
      }
    ],
  },
  meterskid : {
    title    : "Meter Skid",
    prev     : "sand",
    next     : "rtu",
    next_vid : "assets/flythru/4_SandSeperator_to_MeteringSkid.webm",
    prev_bg  : "assets/stills/3_SandSeperator_Still___compressed.jpg",
    in_vid   : 'assets/iso/4_Metering_Skid_ISOV32.webm',
    circleX  : 25,
    circleY  : 38,
    mapx     : 809,
    mapy     : 240,
    linex    : 680,
    liney    : 281,
    lineoff  : 80,
    circleOX : -8,
    circleOY : 5,
    mapclass : 'bottom',
    screens  : [
      {
        bg       : "assets/stills/4_Metering_Skid_Default.jpg",
        flow     : "assets/flows/5_Meteringskid_Flowlines_Updated_10.webm",
        title    : "FB1200 Multi-Run",
        products : [
          {
            display : {
              title1  : 'Bettis RTS CL (Multi-turn)',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/02_Bettis_RTS_Compact_Multi_turn.png',
              content : <BettisRTSMulti />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 52, //percentages for all
              coordY : 49,
              width  : 6,
              height : 17,
            },
            display : {
              title1  : 'Bettis RTS FL Fail-Safe Linear',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
              content : <BettisFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 63, //percentages for all
              coordY : 44,
              width  : 7,
              height : 13,
            },
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 83, //percentages for all
              coordY : 48,
              width  : 8,
              height : 17,
            },
            display : {
              title1  : 'FB3000 Remote Terminal Unit (RTU)',
              title2  : '',
              image   : 'assets/products/40_FB3000_Remote_Terminal_Unit_RTU.png',
              content : <FB3000 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 61.5, //percentages for all
              coordY : 60,
              width  : 4,
              height : 9,
            },
            display : {
              title1  : 'Rosemount™ 214C RTD',
              title2  : 'Temperature Sensor',
              image   : 'assets/products/44_Rosemount_214C_RTD_Temperature_Sensor.png',
              content : <Rosemount214C />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 40.5, //percentages for all
              coordY : 49,
              width  : 4,
              height : 8,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/4_Metering_Skid_Rosemount_3051S_Toggle_Still_Compressed.jpg",
        flow     : "assets/flows/5_Meteringskid_3051S_Flowlines_Updated_9.webm",
        title    : "Rosemount™ 2088",
        products : [
          {
            display : {
              title1  : 'Bettis RTS CL (Multi-turn)',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/02_Bettis_RTS_Compact_Multi_turn.png',
              content : <BettisRTSMulti />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 52, //percentages for all
              coordY : 49,
              width  : 6,
              height : 17,
            },
            display : {
              title1  : 'Bettis RTS FL Fail-Safe Linear',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
              content : <BettisFailSafe />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            map : {
              coordX : 63, //percentages for all
              coordY : 44,
              width  : 7,
              height : 13,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 83, //percentages for all
              coordY : 48,
              width  : 8,
              height : 17,
            },
            display : {
              title1  : 'FB3000 Remote Terminal Unit (RTU)',
              title2  : '',
              image   : 'assets/products/40_FB3000_Remote_Terminal_Unit_RTU.png',
              content : <FB3000 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 61.5, //percentages for all
              coordY : 60,
              width  : 4,
              height : 9,
            },
            display : {
              title1  : 'Rosemount™ 214C RTD',
              title2  : 'Temperature Sensor',
              image   : 'assets/products/44_Rosemount_214C_RTD_Temperature_Sensor.png',
              content : <Rosemount214C />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 40.5, //percentages for all
              coordY : 49,
              width  : 4,
              height : 8,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/4_Metering_Skid_Rosemount_2088_Toggle_Still_Compressed.jpg",
        flow     : "assets/flows/5_Meteringskid_2088_Flowlines_Updated_8.webm",
        title    : "Rosemount™ 3051S",
        products : [
          {
            display : {
              title1  : 'Bettis RTS CL (Multi-turn)',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/02_Bettis_RTS_Compact_Multi_turn.png',
              content : <BettisRTSMulti />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 52, //percentages for all
              coordY : 49,
              width  : 6,
              height : 17,
            },
            display : {
              title1  : 'Bettis RTS FL Fail-Safe Linear',
              title2  : 'Electric Valve Actuator',
              image   : 'assets/products/07_Bettis_RTS_Linear_Fail_Safe.png',
              content : <BettisFailSafe />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 83, //percentages for all
              coordY : 48,
              width  : 8,
              height : 17,
            },
            display : {
              title1  : 'FB3000 Remote Terminal Unit (RTU)',
              title2  : '',
              image   : 'assets/products/40_FB3000_Remote_Terminal_Unit_RTU.png',
              content : <FB3000 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 61.5, //percentages for all
              coordY : 60,
              width  : 4,
              height : 9,
            },
            display : {
              title1  : 'Rosemount™ 214C RTD',
              title2  : 'Temperature Sensor',
              image   : 'assets/products/44_Rosemount_214C_RTD_Temperature_Sensor.png',
              content : <Rosemount214C />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 40.5, //percentages for all
              coordY : 49,
              width  : 4,
              height : 8,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 63, //percentages for all
              coordY : 44,
              width  : 7,
              height : 13,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      }
    ],
  },
  rtu : {
    title    : "RTU/MCC Building",
    prev     : "meterskid",
    next     : "test",
    next_vid : 'assets/flythru/5_Metering_to_Empowerment.webm',
    prev_bg  : 'assets/stills/MeteringSkid.jpg',
    in_vid   : 'assets/iso/5_Empowerment_Box_ISO2.webm',
    circleX  : 43,
    circleY  : 66,
    mapx     : 1182,
    mapy     : 500,
    linex    : 1080,
    liney    : 460,
    lineoff  : 70,
    circleOX : -6,
    circleOY : -10,
    mapclass : '',
    screens  : [
      {
        bg       : "assets/stills/5_Empowerment_Still___compressed.jpg",
        products : [
          {
            display : {
              title1  : 'FloBoss™ 107 Flow Manager',
              title2  : '',
              image   : 'assets/products/38_FloBoss_107.png',
              content : <FloBoss />
            }
          },
          {
            map : {
              coordX : 80, //percentages for all
              coordY : 49,
              width  : 11,
              height : 18,
            },
            display : {
              title1  : 'Empower Services',
              title2  : '',
              image   : 'assets/products/Empower-Services-Graphic-05-2024_2X.png',
              content : <Empowerment />
            }
          },
          {
            map : {
              coordX : 63, //percentages for all
              coordY : 52,
              width  : 10,
              height : 20,
            },
            display : {
              title1  : 'FB3000 Remote Terminal Unit (RTU)',
              title2  : '',
              image   : 'assets/products/40_FB3000_Remote_Terminal_Unit_RTU.png',
              content : <FB3000 />,
              emlogo  : true
            }
          }
        ]
      }
    ]
  },
  test : {
    title    : "Test Separator",
    prev     : "rtu",
    next     : "group",
    next_vid : 'assets/flythru/6_Empowerment_to_TestSeperator.webm',
    prev_bg  : 'assets/stills/5_Empowerment_Still___compressed.jpg',
    in_vid   : 'assets/iso/7_Test_Seperator_Skid_ISO2.webm',
    circleX  : 48,
    circleY  : 60,
    mapx     : 1113,
    mapy     : 559,
    linex    : 996,
    liney    : 508,
    lineoff  : 75,
    circleOX : -6,
    circleOY : -11,
    mapclass : '',
    screens  : [
      {
        bg       : "assets/stills/7_Test_Seperator_Still___compressed.jpg",
        flow     : "assets/flows/6_Test_Seperatorflowlines_Updated_11.webm",
        title    : "Horizontal Test Separator",
        products : [
          {
            map : {
              coordX : 76,
              coordY : 50,
              width  : 3.5,
              height : 6,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 70, //percentages for all
              coordY : 23,
              width  : 4,
              height : 6,
            },
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 53.5,
              coordY : 66,
              width  : 5,
              height : 12,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            map : {
              coordX : 91, //percentages for all
              coordY : 30,
              width  : 5,
              height : 6,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
          {
            map : {
              coordX : 51.6,
              coordY : 42,
              width  : 4,
              height : 10,
            },
            display : {
              title1  : 'Penberthy Level Gauges &',
              title2  : 'Gauge Cocks',
              image   : 'assets/products/15_Penberthy_Level_Gauges_Gauge_Cocks.png',
              content : <PenberthyGauges />
            }
          },
          {
            map : {
              coordX : 77,
              coordY : 34,
              width  : 6,
              height : 13,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 67.5,
              coordY : 39.7,
              width  : 4.5,
              height : 8.5,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 61.3,
              coordY : 51,
              width  : 5,
              height : 10,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 82.5,
              coordY : 57.3,
              width  : 5.5,
              height : 12.5,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'LCO CROSSFIRE',
              title2  : 'Chemical Injection Pump',
              image   : 'assets/products/25_LCO_CROSSFIRE_Chemical_Pump.png',
              content : <LCOCrossfire />
            }
          },
        ]
      },
      {
        bg       : "assets/stills/6_Vert_Seperator_Still___compressed.jpg",
        flow     : "assets/flows/6_Vert_Seperatorflowlines_Toggleupdatedv2_12.webm",
        title    : "Vertical Test Separator",
        products : [
          {
            map : {
              coordX : 52.5,
              coordY : 51,
              width  : 4,
              height : 6,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 54,
              coordY : 60,
              width  : 4,
              height : 8,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Penberthy Level Gauges &',
              title2  : 'Gauge Cocks',
              image   : 'assets/products/15_Penberthy_Level_Gauges_Gauge_Cocks.png',
              content : <PenberthyGauges />
            }
          },
          {
            map : {
              coordX : 73,
              coordY : 31,
              width  : 4,
              height : 10,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            map : {
              coordX : 63,
              coordY : 54,
              width  : 4.5,
              height : 9,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 53.3,
              coordY : 39,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 70,
              coordY : 39.5,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 60.5,
              coordY : 34,
              width  : 6,
              height : 14,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 72.5,
              coordY : 67,
              width  : 6,
              height : 10,
            },
            display : {
              title1  : 'LCO CROSSFIRE',
              title2  : 'Chemical Injection Pump',
              image   : 'assets/products/25_LCO_CROSSFIRE_Chemical_Pump.png',
              content : <LCOCrossfire />
            }
          },
        ]
      }
    ]
  },
  group : {
    title    : "Group Test Separator",
    prev     : "test",
    next     : "groupback",
    next_vid : "assets/flythru/7_TestSeperator_to_GroupSeperator.webm",
    prev_bg  : "assets/stills/6_Vert_Seperator_Still___compressed.jpg",
    in_vid   : 'assets/iso/8_Group_Seperator_ISO2.webm',
    circleX  : 54,
    circleY  : 53,
    mapx     : 1035,
    mapy     : 621,
    linex    : 913,
    liney    : 559,
    lineoff  : 75,
    circleOX : -6,
    circleOY : -10,
    mapclass : '',
    screens  : [
      {
        bg       : "assets/stills/8_GroupSeperator_2088_Defualt_.jpg",
        flow     : "assets/flows/7_Groupseperator__2088_Flowlines_Updated_14.webm",
        title    : "Rosemount™ 2088",
        products : [
          {
            map : {
              coordX : 61, //percentages for all
              coordY : 24,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 67,
              coordY : 57,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 64.5,
              coordY : 49,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 3,
              height : 6,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 92, //percentages for all
              coordY : 34,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
          {
            map : {
              coordX : 84.7,
              coordY : 59.5,
              width  : 4,
              height : 10,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 74.7,
              coordY : 40,
              width  : 5,
              height : 12,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50,
              coordY : 65,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 4088',
              title2  : 'MultiVariable Transmitter',
              image   : 'assets/products/59_Rousemount_4088_MultiVariable_Transmitter.png',
              content : <Rosemount4088 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 5,
              height : 5,
            },
            display : {
              title1  : 'Rosemount™ 5408 Non-Contacting',
              title2  : 'Radar Level Measurement',
              image   : 'assets/products/43_Rosemount_5408_Radar_Level.png',
              content : <Rosemount5408 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/8_GroupSeperator_4088_ToggleStill_.jpg",
        flow     : "assets/flows/7_Groupseperator__4088Toggle_Flowlines_Updated_16.webm",
        title    : "Rosemount™ 4088",
        products : [
          {
            map : {
              coordX : 61, //percentages for all
              coordY : 24,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 67,
              coordY : 57,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 3,
              height : 6,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 92, //percentages for all
              coordY : 34,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
          {
            map : {
              coordX : 84.7,
              coordY : 59.5,
              width  : 4,
              height : 10,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 74.7,
              coordY : 40,
              width  : 5,
              height : 12,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50,
              coordY : 65,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            map : {
              coordX : 64.5,
              coordY : 49,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            map : {
              coordX : 64.5,
              coordY : 49,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : 'Rosemount™ 4088',
              title2  : 'MultiVariable Transmitter',
              image   : 'assets/products/59_Rousemount_4088_MultiVariable_Transmitter.png',
              content : <Rosemount4088 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 5,
              height : 5,
            },
            display : {
              title1  : 'Rosemount™ 5408 Non-Contacting',
              title2  : 'Radar Level Measurement',
              image   : 'assets/products/43_Rosemount_5408_Radar_Level.png',
              content : <Rosemount5408 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/8_Group_Seperator_3051S_Toggle_Still___compressed.jpg",
        flow     : "assets/flows/7_Groupseperator__3051Stoggle_Flowlines_Updated_15.webm",
        title    : "Rosemount™ 3051S",
        products : [
          {
            map : {
              coordX : 61, //percentages for all
              coordY : 24,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 67,
              coordY : 57,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 64.5,
              coordY : 49,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 92, //percentages for all
              coordY : 34,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
          {
            map : {
              coordX : 84.7,
              coordY : 59.5,
              width  : 4,
              height : 10,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 74.7,
              coordY : 40,
              width  : 5,
              height : 12,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50,
              coordY : 65,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 4088',
              title2  : 'MultiVariable Transmitter',
              image   : 'assets/products/59_Rousemount_4088_MultiVariable_Transmitter.png',
              content : <Rosemount4088 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 5,
              height : 5,
            },
            display : {
              title1  : 'Rosemount™ 5408 Non-Contacting',
              title2  : 'Radar Level Measurement',
              image   : 'assets/products/43_Rosemount_5408_Radar_Level.png',
              content : <Rosemount5408 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/8_Group_Seperator_FB12000_Toggle_Still___compressed.jpg",
        flow     : "assets/flows/7_Groupseperator__Fb12000toggle_Flowlines_Updated_17.webm",
        title    : "FB1200 Multi-Run",
        products : [
          {
            map : {
              coordX : 61, //percentages for all
              coordY : 24,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 67,
              coordY : 57,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 3,
              height : 6,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 92, //percentages for all
              coordY : 34,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
	  {
            map : {
              coordX : 84.7,
              coordY : 59.5,
              width  : 4,
              height : 10,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
{
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 74.7,
              coordY : 40,
              width  : 5,
              height : 12,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 50,
              coordY : 65,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
	  {
            map : {
              coordX : 64.5,
              coordY : 49,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 4088',
              title2  : 'MultiVariable Transmitter',
              image   : 'assets/products/59_Rousemount_4088_MultiVariable_Transmitter.png',
              content : <Rosemount4088 />
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 5,
              height : 5,
            },
            display : {
              title1  : 'Rosemount™ 5408 Non-Contacting',
              title2  : 'Radar Level Measurement',
              image   : 'assets/products/43_Rosemount_5408_Radar_Level.png',
              content : <Rosemount5408 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      }
    ],
  },
  groupback : {
    title    : "Group Test Separator (Back View)",
    prev     : "group",
    next     : "service",
    next_vid : 'assets/flythru/8_GroupSeperator_to_BackSide.webm',
    prev_bg  : 'assets/stills/7_Test_Seperator_Still___compressed.jpg',
    circleX  : 54,
    circleY  : 53,
    mapx     : 0,
    mapy     : 0,
    linex    : 0,
    liney    : 0,
    lineoff  : 0,
    circleOX : 0,
    circleOY : 0,
    mapclass : '',
    screens  : [
      {
        bg       : 'assets/stills/9_Group_Seperator_Back_Default____compressed.jpg',
        flow     : 'assets/flows/8_Groupseperatorback_Flowlines_Updatedv2_19.webm',
        title    : "Rosemount™ 5408",
        products : [
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 57,
              coordY : 77,
              width  : 8,
              height : 12,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 44,
              coordY : 68,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 3,
              height : 6,
            },
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 48.5, //percentages for all
              coordY : 32,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
{
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 68,
              coordY : 75,
              width  : 13,
              height : 11,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 59.5,
              coordY : 36,
              width  : 5,
              height : 15,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
{
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
{
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Rosemount™ 4088',
              title2  : 'MultiVariable Transmitter',
              image   : 'assets/products/59_Rousemount_4088_MultiVariable_Transmitter.png',
              content : <Rosemount4088 />
            }
          },
          {
            map : {
              coordX : 81.5,
              coordY : 32,
              width  : 4,
              height : 7,
            },
            display : {
              title1  : 'Rosemount™ 5408 Non-Contacting',
              title2  : 'Radar Level Measurement',
              image   : 'assets/products/43_Rosemount_5408_Radar_Level.png',
              content : <Rosemount5408 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0,
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      },
      {
        bg       : "assets/stills/9_Group_Seperator_Back_5301_Toggle_Still_Compressed.jpg",
        flow     : "assets/flows/8_Groupseperatorback_5301Toggle_Flowlines_Updated_18.webm",
        title    : "Rosemount™ 5300",
        products : [
          {
            display : {
              title1  : 'PointWatch Eclipse<sup>®</sup> PIRECL',
              title2  : 'Infrared Gas Detector',
              image   : 'assets/products/34_Det_Tronics_Eclipse_LEL_PIRECL_Gas_Detector.png',
              content : <PointWatchEclipse />
            }
          },
          {
            map : {
              coordX : 57,
              coordY : 77,
              width  : 8,
              height : 12,
            },
            display : {
              title1  : 'MicroMotion Coriolis Meters',
              title2  : '',
              image   : 'assets/products/24_Micro_Motion_Coriolis_Meters.png',
              content : <MicroMotion />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher L2e Electric Level Controller',
              title2  : '',
              image   : 'assets/products/13_Fisher_L2e_Level_Controller.png',
              content : <FisherL2e />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 44,
              coordY : 68,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Fisher™ easy-Drive™ & 200L',
              title2  : 'Electric Actuators',
              image   : 'assets/products/11_Fisher_200L_Easy_Drive_Electric_Actuator.png',
              content : <Fisher200L />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Rosemount™ 2088 Gauge & Absolute',
              title2  : 'Pressure Transmitter',
              image   : 'assets/products/21_Rosemount_2088_Gauge_Absolute.png',
              content : <Rosemount2088 />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : "Rosemount™ 3051S",
              title2  : 'Multivariable Transmitter',
              image   : 'assets/products/20_Rosemount_3051S_Multivariable_Transmitter.png',
              content : <Rosemount3051S />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 81.5,
              coordY : 34,
              width  : 6,
              height : 10,
            },
            display : {
              title1  : 'Rosemount™ 5300 Level Transmitter',
              title2  : '',
              image   : 'assets/products/42_Rosemount_5301_Guided_Wave_Radar.png',
              content : <Rosemount5300 />,
              emlogo : true
            }
          },
          {
            map : {
              coordX : 48.5, //percentages for all
              coordY : 32,
              width  : 5,
              height : 9,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
          {
            display : {
              title1  : 'Bettis RTS CM Series Linear Fail Last',
              title2  : '',
              image   : 'assets/products/03_Bettis_RTS_Compact_Linear.png',
              content : <BettisRTS />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 68,
              coordY : 75,
              width  : 13,
              height : 11,
            },
            display : {
              title1  : 'Bettis RTS FQ Fail-Safe',
              title2  : 'Quarter-turn Electric valve Actuator',
              image   : 'assets/products/08_Bettis_RTS_Quarter_turn_Fail_Safe.png',
              content : <BettisQuarterTurnFailSafe />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 59.5,
              coordY : 36,
              width  : 5,
              height : 15,
            },
            display : {
              title1  : 'Crosby PRV J-Series',
              title2  : '',
              image   : 'assets/products/16_Crosby_Pressure_Relief_Valves.png',
              content : <CrosbyValve />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Det-Tronics FlexVu<sup>®</sup> Universal Display',
              title2  : '',
              image   : 'assets/products/36_Det_Tronics_FlexVU_Universal_Display.png',
              content : <DetTronicsFlexVU />
            }
          },
          {
            display : {
              title1  : 'FB1200 Flow Computer',
              title2  : 'Multi-Run Explosion-Proof<br />Flow Computer for Gas and Liquids',
              image   : 'assets/products/39-FB1200-Multi-Run-Explosion-Proof-Flow-Computer-for-Gas-and-Liquids.png',
              content : <FB1200 />
            }
          },
          {
            display : {
              title1  : 'Rosemount™ 4088',
              title2  : 'MultiVariable Transmitter',
              image   : 'assets/products/59_Rousemount_4088_MultiVariable_Transmitter.png',
              content : <Rosemount4088 />
            }
          },
          {
            display : {
              title1  : 'Rosemount™ 5408 Non-Contacting',
              title2  : 'Radar Level Measurement',
              image   : 'assets/products/43_Rosemount_5408_Radar_Level.png',
              content : <Rosemount5408 />,
              emlogo : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ HP Control Valve Series',
              title2  : '',
              image   : 'assets/products/49_Fisher_HPT_Control_Valve.png',
              content : <FisherHPT />,
              emlogo : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ easy-e™ ET Control Valve',
              title2  : '',
              image   : 'assets/products/58_Fisher_ET_Control_Valve.png',
              content : <FisherET />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Det-Tronics GT3000 with GTS Sensor',
              title2  : 'Toxic Gas Detector',
              image   : 'assets/products/35_Det_Tronics_GT_3000_w_GTS_Sensor_Toxic_Gas_Detector.png',
              content : <DetTronicsGT3000 />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Linear, Multi-turn',
              title2  : '& Quarter Turn Electric Actuators',
              image   : 'assets/products/new/num05.png',
              content : <BettisTorqPlus />
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Multi-turn',
              title2  : 'Quarter Turn',
              image   : 'assets/products/new/04-Bettis-TorqPlus-Multi-turn_Quarter-Turn.png',
              content : <Torqplus />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Bettis TorqPlus Quarter Turn Fail Safe',
              title2  : '',
              image   : 'assets/products/new/09-Bettis-TorqPlus-Quarter-Turn-Fail-Safe.png',
              content : <Torqplusfail />,
              emlogo  : true
            }
          }
        ]
      }
    ]
  },
  service : {
    title    : "Service",
    prev     : "groupback",
    next     : "tanks",
    next_vid : 'assets/flythru/9_BackSide_to_Trucks.webm',
    prev_bg  : 'assets/stills/8_GroupSeperator_2088_Defualt____compressed.jpg',
    in_vid   : 'assets/iso/10_Trucks_ISO2.webm',
    circleX  : 70,
    circleY  : 32,
    mapx     : 733,
    mapy     : 711,
    linex    : 641,
    liney    : 658,
    lineoff  : 75,
    circleOX : -5,
    circleOY : -12,
    mapclass : '',
    screens  : [
      {
        bg       : "assets/stills/10_Trucks_Still___compressed.jpg",
        products : [
          {
            map : {
              coordX : 57, //percentages for all
              coordY : 65,
              width  : 68,
              height : 55,
            },
            display : {
              title1  : 'Services',
              title2  : '',
              image   : 'assets/products/48-Spartan-MRP-Upstream-Services_.png',
              content : <ServiceTrucks />
            }
          }
        ]
      }
    ],
  },
  tanks : {
    title    : "Tanks",
    prev     : "service",
    next     : "tanksin",
    next_vid : 'assets/flythru/10_Trucks_to_TankProtectionZoomOut.webm',
    prev_bg  : 'assets/stills/10_Trucks_Still___compressed.jpg',
    in_vid   : 'assets/iso/11_TankProtection_Zoomout_ISO2.webm',
    circleX  : 38,
    circleY  : 16,
    mapx     : 536,
    mapy     : 433,
    linex    : 425,
    liney    : 337,
    lineoff  : 20,
    circleOX : -6,
    circleOY : -11,
    mapclass : '',
    screens  : [
      {
        bg       : "assets/stills/11_TankProtection_Zoomout_Still___compressed.jpg",
        products : [
          {
            map : {
              coordX : 70, //percentages for all
              coordY : 73,
              width  : 8,
              height : 12,
            },
            display : {
              title1  : 'Rosemount™ 928',
              title2  : 'Wireless Gas Monitor',
              image   : 'assets/products/23_Rosemount_928_Wireless_Gas_Monitor_Detection.png',
              content : <Rosemount928 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 32, //percentages for all
              coordY : 60,
              width  : 6,
              height : 10,
            },
            display : {
              title1  : 'Det-Tronics FlexSight™ LS2000',
              title2  : 'Infrared Hydrocarbon Gas Detector ',
              image   : 'assets/products/33_Det_Tronics_Open_Path_LS2000_Detector.png',
              content : <DettronicsLS2000 />
            }
          },
          {
            map : {
              coordX : 38, //percentages for all
              coordY : 62.5,
              width  : 3,
              height : 5,
            },
            display : {
              title1  : 'Det-Tronics X3301 Multispectrum',
              title2  : 'Flame Detector',
              image   : 'assets/products/37_Det_Tronics_X3301_Multispectrum_Infrared_Flame_Detector.png',
              content : <DettronicsX3301 />
            }
          },
        ]
      }
    ]
  },
  tanksin : {
    title    : "Tanks",
    prev     : "tanks",
    next     : "flaire",
    next_vid : 'assets/flythru/11_TankProtectionZoomout_to_TankProtectionCloseup.webm',
    prev_bg  : 'assets/stills/11_TankProtection_Zoomout_Still___compressed.jpg',
    circleX  : 38,
    circleY  : 16,
    mapx     : 0,
    mapy     : 0,
    linex    : 0,
    liney    : 0,
    lineoff  : 0,
    circleOX : 0,
    circleOY : 0,
    mapclass : '',
    video    : {
      title   : "SpartanPRO Tank Program",
      videoId : 'TFgwJWKdKgA',
      video   : 'assets/products/spartan-tank-program2.mp4'
    },
    screens : [
      {
        bg       : "assets/stills/12_TankProtection_850_Default_Still___compressed.jpg",
        title    : 'Enardo™ Series 850',
        products : [
          {
            display : {
              title1  : 'Enardo™ Model',
              title2  : '2000 Emergency Relief Vent',
              image   : 'assets/products/53_Enardo_2000_2100_2500_Series.png',
              content : <Enardo2001 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 68, //percentages for all
              coordY : 65,
              width  : 11,
              height : 30,
            },
            display : {
              title1  : 'Smart Enardo™ ES 660 & 665',
              title2  : 'Wireless Thief Hatch',
              image   : 'assets/products/new/54-Smart-Enardo-ES--660_665-Wireless-Thief-Hatch.png',
              content : <EnardoES665 />
            }
          },
          {
            map : {
              coordX : 79, //percentages for all
              coordY : 58,
              width  : 8,
              height : 18,
            },
            display : {
              title1  : 'Enardo™ 2000 Smart',
              title2  : 'Emergency Relief Vent',
              image   : 'assets/products/29_Smart_Emergency_Pressure_Relief_Vent.png',
              content : <Enardo2000Smart />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Tank Protection Solutions',
              title2  : 'Smart Enardo Enhanced Sealing<br>Pressure Vacuum Relief Valve',
              image   : 'assets/products/57_Tank_Protection_Solutions_Enardo_Enhanced_Sealing_Pressure_Vacuum_Relief_Valve_copy.png',
              content : <TankProtectionSolutionsTwo />
            }
          },
          {
            map : {
              coordX : 53, //percentages for all
              coordY : 52,
              width  : 12,
              height : 20,
            },
            display : {
              title1  : 'Enardo 850 & 950 Enhanced Sealing',
              title2  : 'Pressure Vacuum  Relief Valve',
              image   : 'assets/products/new/41.png',
              content : <TankProtectionSolutions />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Tank Blanketing Solutions',
              title2  : '',
              image   : 'assets/products/51_Fisher_T208_Y696_1290_Recovery_Solutions.png',
              content : <TankBlanketingSolutions />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Vapour Recovery Solutions',
              title2  : '',
              image   : 'assets/products/51_Fisher_T208_Y696_1290_Recovery_Solutions.png',
              content : <VaporRecoverySolutions/>,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Smart Tank Blanketing &',
              title2  : 'Vapor Recovery Regulator',
              image   : 'assets/products/55_Smart_Tank_Blanketing_Vapor_Recovery_Regulator.png',
              content : <SmartTank />
            }
          }
        ]
      },
      {
        bg       : "assets/stills/12_TankProtection_950_Toggle_Still_V2_Compressed.jpg",
        title    : 'Enardo™ Series ES-950',
        products : [
          {
            display : {
              title1  : 'Enardo™ Model',
              title2  : '2000 Emergency Relief Vent',
              image   : 'assets/products/53_Enardo_2000_2100_2500_Series.png',
              content : <Enardo2001 />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 68, //percentages for all
              coordY : 65,
              width  : 11,
              height : 30,
            },
            display : {
              title1  : 'Smart Enardo™ ES 660 & 665',
              title2  : 'Wireless Thief Hatch',
              image   : 'assets/products/new/54-Smart-Enardo-ES--660_665-Wireless-Thief-Hatch.png',
              content : <EnardoES665 />
            }
          },
          {
            map : {
              coordX : 79, //percentages for all
              coordY : 58,
              width  : 8,
              height : 18,
            },
            display : {
              title1  : 'Enardo™ 2000 Smart',
              title2  : 'Emergency Relief Vent',
              image   : 'assets/products/29_Smart_Emergency_Pressure_Relief_Vent.png',
              content : <Enardo2000Smart />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 53, //percentages for all
              coordY : 52,
              width  : 12,
              height : 20,
            },
            display : {
              title1  : 'Tank Protection Solutions',
              title2  : 'Smart Enardo Enhanced Sealing<br>Pressure Vacuum Relief Valve',
              image   : 'assets/products/57_Tank_Protection_Solutions_Enardo_Enhanced_Sealing_Pressure_Vacuum_Relief_Valve_copy.png',
              content : <TankProtectionSolutionsTwo />
            }
          },
          {
            display : {
              title1  : 'Enardo 850 & 950 Enhanced Sealing',
              title2  : 'Pressure Vacuum  Relief Valve',
              image   : 'assets/products/new/41.png',
              content : <TankProtectionSolutions />,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Fisher™ Tank Blanketing',
              title2  : '',
              image   : 'assets/products/51_Fisher_T208_Y696_1290_Recovery_Solutions.png',
              content : <TankBlanketingSolutions />
            }
          },
          {
            display : {
              title1  : 'Vapour Recovery Solutions',
              title2  : '',
              image   : 'assets/products/51_Fisher_T208_Y696_1290_Recovery_Solutions.png',
              content : <VaporRecoverySolutions/>,
              emlogo  : true
            }
          },
          {
            display : {
              title1  : 'Smart Tank Blanketing &',
              title2  : 'Vapor Recovery Regulator',
              image   : 'assets/products/55_Smart_Tank_Blanketing_Vapor_Recovery_Regulator.png',
              content : <SmartTank />
            }
          }
        ]
      }
    ]
  },
  flaire : {
    title    : "Flare Stack",
    prev     : "tanksin",
    next     : "prodskid",
    next_vid : 'assets/flythru/12_TankProctectionCloseup_to_Flaire.webm',
    prev_bg  : 'assets/stills/12_TankProtection_850_Default_Still___compressed.jpg',
    out_vid  : 'assets/flythru/13_Flare_to_SiteOutro.webm',
    in_vid   : 'assets/iso/13_Flare_Camera_ISO2.webm',
    circleX  : 20,
    circleY  : 1,
    mapx     : 180,
    mapy     : 184,
    linex    : 100,
    liney    : 300,
    lineoff  : 20,
    circleOX : -6,
    circleOY : 11,
    mapclass : 'bottom',
    combo_menu: {
      menus : [
        {
          name1  : 'DHV & Himark Valve',
          value1 : 0,
          name2  : 'Trunnion Ball Valve',
          value2 : 1
        },
        {
          name1  : 'Rugged PTZ Camera',
          value1 : 5,
          name2  : 'PTZ Dome Camera',
          value2 : 10
        }
      ],
      values : {
        5  : 0,
        6  : 1,
        10 : 2,
        11 : 3
      }
    },
    screens  : [
      {
        bg       : "assets/stills/13_FlareStack_Cam_Default_Still___compressed.jpg",
        title    : 'Default',
        products : [
          {
            map : {
              coordX : 51, //percentages for all
              coordY : 51,
              width  : 8,
              height : 10,
            },
            display : {
              title1  : 'DHV Valve Line',
              title2  : '',
              image   : 'assets/products/17_DHV_Valve_Line.png',
              content : <DHV />
            }
          },
          {
            map : {
              coordX : 67, //percentages for all
              coordY : 62,
              width  : 10,
              height : 10,
            },
            display : {
              title1  : 'Enardo™ Series',
              title2  : 'Flame Arrestors',
              image   : 'assets/products/46_Enardo_Flame_Arrestors.png',
              content : <EnardoFlame />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 69, //percentages for all
              coordY : 8,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Pelco Esprit<sup>®</sup>',
              title2  : 'Rugged PTZ Camera',
              image   : 'assets/products/31_Esprit_Enhanced_Series_Rugged_PTZ_Camera.png',
              content : <EspiritCamera />
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Pelco Spectra',
              title2  : 'Enhanced PTZ Dome',
              image   : 'assets/products/30_Spectra_Enhanced_7_Series_PTZ_Dome_Camera.png',
              content : <SpectraCamera />
            }
          },
          {
            map : {
              coordX : 41, //percentages for all
              coordY : 44,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fox Thermal',
              title2  : 'Gas Mass Flow Meter',
              image   : 'assets/products/50_Fox_thermal_Meter.png',
              content : <FoxMeter />
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'GVS Trunnion',
              title2  : 'Ball Valve',
              image   : 'assets/products/18_GVS_Valve_Lines.png',
              content : <GVSValve />
            }
          }
        ]
      },
      {
        bg       : 'assets/stills/13_FlareStack_DHV_Toggle_Still_Compressed.jpg',
        title    : 'DHV',
        products : [
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'DHV Valve Line',
              title2  : '',
              image   : 'assets/products/17_DHV_Valve_Line.png',
              content : <DHV />
            }
          },
          {
            map : {
              coordX : 67, //percentages for all
              coordY : 62,
              width  : 10,
              height : 10,
            },
            display : {
              title1  : 'Enardo™ Series',
              title2  : 'Flame Arrestors',
              image   : 'assets/products/46_Enardo_Flame_Arrestors.png',
              content : <EnardoFlame />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 69, //percentages for all
              coordY : 8,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Pelco Esprit<sup>®</sup>',
              title2  : 'Rugged PTZ Camera',
              image   : 'assets/products/31_Esprit_Enhanced_Series_Rugged_PTZ_Camera.png',
              content : <EspiritCamera />
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Pelco Spectra',
              title2  : 'Enhanced PTZ Dome',
              image   : 'assets/products/30_Spectra_Enhanced_7_Series_PTZ_Dome_Camera.png',
              content : <SpectraCamera />
            }
          },
          {
            map : {
              coordX : 41, //percentages for all
              coordY : 44,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fox Thermal',
              title2  : 'Gas Mass Flow Meter',
              image   : 'assets/products/50_Fox_thermal_Meter.png',
              content : <FoxMeter />
            }
          },
          {
            map : {
              coordX : 51, //percentages for all
              coordY : 51,
              width  : 8,
              height : 10,
            },
            display : {
              title1  : 'GVS Trunnion',
              title2  : 'Ball Valve',
              image   : 'assets/products/18_GVS_Valve_Lines.png',
              content : <GVSValve />
            }
          }
        ]
      },
      {
        bg       : 'assets/stills/13_FlareStack_securitycamera_Toggle_Still_Compressed.jpg',
        title    : 'Security Camera',
        products : [
          {
            map : {
              coordX : 51, //percentages for all
              coordY : 51,
              width  : 8,
              height : 10,
            },
            display : {
              title1  : 'DHV Valve Line',
              title2  : '',
              image   : 'assets/products/17_DHV_Valve_Line.png',
              content : <DHV />
            }
          },
          {
            map : {
              coordX : 67, //percentages for all
              coordY : 62,
              width  : 10,
              height : 10,
            },
            display : {
              title1  : 'Enardo™ Series',
              title2  : 'Flame Arrestors',
              image   : 'assets/products/46_Enardo_Flame_Arrestors.png',
              content : <EnardoFlame />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Pelco Esprit<sup>®</sup>',
              title2  : 'Rugged PTZ Camera',
              image   : 'assets/products/31_Esprit_Enhanced_Series_Rugged_PTZ_Camera.png',
              content : <EspiritCamera />
            }
          },
          {
            map : {
              coordX : 69, //percentages for all
              coordY : 12,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Pelco Spectra',
              title2  : 'Enhanced PTZ Dome',
              image   : 'assets/products/30_Spectra_Enhanced_7_Series_PTZ_Dome_Camera.png',
              content : <SpectraCamera />
            }
          },
          {
            map : {
              coordX : 41, //percentages for all
              coordY : 44,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fox Thermal',
              title2  : 'Gas Mass Flow Meter',
              image   : 'assets/products/50_Fox_thermal_Meter.png',
              content : <FoxMeter />
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'GVS Trunnion',
              title2  : 'Ball Valve',
              image   : 'assets/products/18_GVS_Valve_Lines.png',
              content : <GVSValve />
            }
          }
        ]
      },
      {
        bg       : 'assets/stills/13_Flare_DomeCam_StriaghtBarValve_Toggle.jpg',
        title    : 'PTZ Dome Camera',
        products : [
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'DHV Valve Line',
              title2  : '',
              image   : 'assets/products/17_DHV_Valve_Line.png',
              content : <DHV />
            }
          },
          {
            map : {
              coordX : 67, //percentages for all
              coordY : 62,
              width  : 10,
              height : 10,
            },
            display : {
              title1  : 'Enardo™ Series',
              title2  : 'Flame Arrestors',
              image   : 'assets/products/46_Enardo_Flame_Arrestors.png',
              content : <EnardoFlame />,
              emlogo  : true
            }
          },
          {
            map : {
              coordX : 0, //percentages for all
              coordY : 0,
              width  : 0,
              height : 0,
            },
            display : {
              title1  : 'Pelco Esprit<sup>®</sup>',
              title2  : 'Rugged PTZ Camera',
              image   : 'assets/products/31_Esprit_Enhanced_Series_Rugged_PTZ_Camera.png',
              content : <EspiritCamera />
            }
          },
          {
            map : {
              coordX : 69, //percentages for all
              coordY : 12,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Pelco Spectra',
              title2  : 'Enhanced PTZ Dome',
              image   : 'assets/products/30_Spectra_Enhanced_7_Series_PTZ_Dome_Camera.png',
              content : <SpectraCamera />
            }
          },
          {
            map : {
              coordX : 41, //percentages for all
              coordY : 44,
              width  : 5,
              height : 8,
            },
            display : {
              title1  : 'Fox Thermal',
              title2  : 'Gas Mass Flow Meter',
              image   : 'assets/products/50_Fox_thermal_Meter.png',
              content : <FoxMeter />
            }
          },
          {
            map : {
              coordX : 51, //percentages for all
              coordY : 51,
              width  : 8,
              height : 10,
            },
            display : {
              title1  : 'GVS Trunnion',
              title2  : 'Ball Valve',
              image   : 'assets/products/18_GVS_Valve_Lines.png',
              content : <GVSValve />
            }
          }
        ]
      }
    ]
  }
}
