import React from 'react'

const LCOCrossfire = () => {
  return (
    <div>
      <p>Utilizing advanced technology, this pump offers unmatched accuracy, reliability, and performance, ideal for industries optimizing operations and reducing costs. With its compact design, easy installation, and low maintenance, the LCO Crossfire Injection Pump is a valuable investment for organizations enhancing productivity and profitability while reducing environmental impact.</p>
      <h1>Features:</h1>
      <ul className="ul1">
    <li className="li1">Methane emissions elimination: generates carbon offsets and credits with quick ROI</li>
    <li>Production optimization:
        <ul>
            <li>Solar-powered, ideal for remote locations</li>
            <li>Minimizes chemical waste</li>
            <li>Delivers chemical proportionate to flow</li>
            <li>High efficiency 24 VDC motor ~5 Watts @ 20L/day into 100 PSI injection</li>
            <li>Replaces up to 4 pneumatic pumps (1-720L/day up to 10000 PSI)</li>
        </ul>
    </li>
    <li>Improved reliability:
        <ul>
            <li>Modbus capabilities for automation and SCADA connectivity</li>
            <li>Multiple control and automation options</li>
            <li>Optional Bluetooth connectivity</li>
        </ul>
    </li>
    <li>Volume tracking:
        <ul>
            <li>On-board volume totalization for each head</li>
            <li>Eliminates manual data collection</li>
        </ul>
    </li>
</ul>

      <h1>Applications:</h1>
      <ul className="ul1">
 	<li className="li1">Oil and Gas Production</li>
 	<li className="li1">Methane Emissions Reduction</li>
 	<li className="li1">Remote Locations</li>
 	<li className="li1">Automation and SCADA Connectivity</li>
 	<li className="li1">Volume Tracking</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/remote-automation-scada/low-power-environmental-solutions/lco-crossfire-injection-pump/">LCO Crossfire Chemical Injection Pump Product Page</a></li>
      </ul>
    </div>
  )
}

export default LCOCrossfire
