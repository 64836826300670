const Rosemount928 = () => {
  return (
    <div>
      <p>The Rosemount™ 928 Wireless Gas Monitor is a cutting-edge product offered by Spartan Controls that enables remote and wireless monitoring of gas levels in industrial environments. It provides real-time data on gas concentrations, allowing for proactive detection and response to potential hazards, enhancing safety and operational efficiency.</p>
      <h1>Features:</h1>
      <ul class="ul1">
    <li class="li1">Monitoring of toxic gases: such as hydrogen sulfide, carbon monoxide, and oxygen depletion</li>
    <li class="li1">Utilizes secure and cost-effective WirelessHART communication protocol for reliable data transmission</li>
    <li class="li1">Hot-swappable electrochemical sensor module for easy field replacement</li>
    <li class="li2">Intrinsically safe power module with long battery life</li>
    <li class="li1">High accuracy in gas level measurements</li>
</ul>
<h1>Applications:</h1>
<ul class="ul1">
 	 <li class="li1">Minimizing environmental impact through gas leak detection</li>
   <li class="li1">Optimizing processes for energy efficiency</li>
   <li class="li1">Enabling remote monitoring</li>
   <li class="li1">Oil & gas uncontrolled and controlled tanks</li>
   <li class="li1">Test & group separator gas monitoring</li>
   <li class="li1">Wireless gas monitoring for multiple industrial environments</li>
   <li class="li1">Wellhead monitoring</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/measurement-instrumentation/wireless-systems/wireless-flame-gas-detection/rosemount-928-wireless-gas-monitor/" target="_blank" rel="noopener noreferrer">Rosemount 928 Wireless Gas Monitor Product Page</a>
        </li>
      </ul>
    </div>
  )
}
  
export default Rosemount928

